import React, { useEffect, useState } from "react"

import Menu from "./Menu/Menu"

import styles from "../../css/ManagerPanel.module.css"

const ManagerPanel = props => {

  const [showMenu, setShowMenu] = useState(false)

  const openHeightClass = props.isManagerLoggedInWithCompany ? styles.openHeightWithCompany : styles.openHeight
  const panelHeightClass = showMenu ? openHeightClass : styles.closedHeight
  const menuIconImageClass = showMenu ? styles.menuIconImageClose : styles.menuIconImageOpen
  const managerName = props.companyInfo.name ? props.manager.name+' ('+props.companyInfo.name+')' : props.manager.name

  return (
    
    <div className={styles.wrapper}>
      <div className={[styles.panel, panelHeightClass].join(' ')}>
        <div className={[styles.top, styles.closedHeight].join(' ')}>
          <div className={[styles.item, styles.managerName].join(' ')}>
            {managerName}
          </div>
          <div
            className={[styles.item, styles.menuIcon, menuIconImageClass].join(' ')}
            onClick={() => setShowMenu(!showMenu)}
          >
          </div>
        </div>
        <Menu
          setShowMenu={setShowMenu}
          isManagerLoggedInWithCompany={props.isManagerLoggedInWithCompany}
        />
      </div>
    </div>
  )
}

export default ManagerPanel