import React, { useState, useEffect } from "react"

import Title from "./Title"
import Message from "./Message"
import Input from "./Input/Input"
import DocumentSigning from "./DocumentSigning"

import getRequiredDocumentInputs from "../functions/getRequiredDocumentInputs"
import getUserEmailInputName from "../functions/getUserEmailInputName"

import { listFunctions as inputListFunctions } from "../lists/inputs";

import styles from "../css/Summary.module.css"
import { default as pageStyles } from "../css/Page.module.css"

const DocumentHandling = props => {

  const userEmailInputName = getUserEmailInputName(props.process)

  const requiredInputs = getRequiredDocumentInputs(props.documents)
  const requiredInputValidations = requiredInputs.reduce((validations, input) => {
    validations.push(props.inputState[input.name].validated)
    return validations
  }, [])

  const [requiredInputsError, setRequiredInputsError] = useState('')

  useEffect(() => {
    const unvalidatedRequiredInputs = requiredInputs.filter((input) => {
      return !props.inputState[input.name].validated
    })
    
    const unvalidatedRequiredInputPlaceholders = unvalidatedRequiredInputs.reduce((placeholders, unvalidatedInput) => {
      const input = inputListFunctions.getInput(unvalidatedInput.name)
      const placeholder = input.placeholder || ''
      if (placeholder && !placeholders.includes(placeholder)) {
        placeholders.push(placeholder)
      }
      return placeholders
    }, [])

    setRequiredInputsError(unvalidatedRequiredInputPlaceholders.length > 0
      ? 'Följande fält behöver fyllas i innan dokument kan signeras: '+unvalidatedRequiredInputPlaceholders.join(', ')
      : '')
  }, requiredInputValidations)

  return (
    <div>
      <Title text="Dokument" />
      <div className={styles.itemContainer}>
        <div className="buddy-content-box sorbet">
          <div>
            <Message text='Följande dokument behöver signeras:' weight='bold' />
            {props.documents.map((document, index) => (
              <Message text={document.title} key={index} />
            ))}
          </div>
        </div>
        <div>
          <div className={pageStyles.inputContainer}>
            <Input
              name="newOwnerSignatoryName"
              title="Firmatecknare för tillträdande kund:"
              inputState={props.inputState}
              onInputChange={props.onInputChange}
            />
            <Input
              name="newOwnerSignatoryEmail"
              inputState={props.inputState}
              onInputChange={props.onInputChange}
            />
            <Input
              name="newOwnerSignatoryPersonalIdentityNumber"
              inputState={props.inputState}
              onInputChange={props.onInputChange}
            />
          </div>
          <div className={pageStyles.inputContainer}>
            <Input
              name="currentOwnerSignatoryName"
              title="Firmatecknare för frånträdande kund:"
              inputState={props.inputState}
              onInputChange={props.onInputChange}
            />
            <Input
              name="currentOwnerSignatoryEmail"
              inputState={props.inputState}
              onInputChange={props.onInputChange}
            />
            <Input
              name="currentOwnerSignatoryPersonalIdentityNumber"
              inputState={props.inputState}
              onInputChange={props.onInputChange}
            />
            <Input
              name="caseManagerEmail"
              title="Övervakare"
              inputState={props.inputState}
              onInputChange={props.onInputChange}
              hide={(props.inputState.caseManagerIsSignatory.value === 'yes')}
            />
            <Input
              name="isUserDocumentViewer"
              inputState={props.inputState}
              onInputChange={props.onInputChange}
            />
            <Input
              name={userEmailInputName}
              title="Övervakare"
              inputState={props.inputState}
              onInputChange={props.onInputChange}
              hide={(props.inputState.isUserDocumentViewer.value === false)}
            />
          </div>
          {requiredInputsError && (
            <Message text={requiredInputsError} error={true} />
          )}
        </div>
        {!requiredInputsError && (
          <DocumentSigning
            inputState={props.inputState}
            onInputChange={props.onInputChange}
            documents={props.documents}
            completeCase={props.completeCase}
            caseID={props.caseID}
            caseNumber={props.caseNumber}
            process={props.process}
          />
        )}
      </div>
    </div>
  )
}
  
export default DocumentHandling