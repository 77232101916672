import React, { useState, useEffect } from "react"

import Manager from "./Manager"

import styles from "../../../css/Page.module.css"

const ManagerTab = props => {

  const removeCompanyManager = (managerID) => {
    const newManagerList = props.managerList.filter((manager) => {
      return manager.id !== managerID
    })
    props.setCompany(previousData  => ({...previousData, managerList: newManagerList}))
  }

  let classNames = []
  if (props.hide) {
    classNames.push(styles.hidden)
  }

  return(
    <div className={classNames.join(" ")}>
      <div className={styles.separatedItemContainer}>
        {props.managerList.map((manager, index) => (
          <Manager
            companyID={props.companyID}
            data={manager} 
            removeCompanyManager={removeCompanyManager}
            accessToken={props.accessToken}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}
  
export default ManagerTab