import config from "../config"

const doFetchCaseAPI = async (controller, method, data) => {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer "+config.api.case.key
        },
        body: JSON.stringify(data)
    };
    const url = config.api.case.url+"/"+controller+"/"+method

    return fetch(url, options)
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            console.error('Error:', error)
            return null
        })
}

export default doFetchCaseAPI