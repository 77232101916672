import React, { useState } from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"
import Message from "../../components/Message"

import styles from "../../css/Page.module.css"

const DivertNumber = props => {

  return (
    <div>
      <Header title="Ska numret vidarekopplas?" />
      <div className={styles.inputContainer}>
        {props.condition.isDateDiff && (
          <Message text='Ska numret vidarekopplas under glapptiden mellan start- och slutdatum?' />
        )}
        <Input
          name="divertNumberChoice"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="divertToNumber"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(props.inputState.divertNumberChoice.value == 'yes')}
        />
      </div>
      {/*<NavigationButtons
        inputState={props.inputState}
        process={props.process}
        />*/}
    </div>
  )
}

export default DivertNumber