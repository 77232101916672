import React, {useState, useEffect} from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"
import NewOwnerSignatory from "../../components/NewOwnerSignatory"

import getCompanyType from "../../functions/getCompanyType"

import styles from "../../css/Page.module.css"

const NewOwner = props => {

  const [isPrivate, setIsPrivate] = useState(false)

  useEffect(() => {
    if (props.inputState.newOwnerOrgnr.validated) {
      const companyType = getCompanyType(props.inputState.newOwnerOrgnr.value)
      if (companyType === 'private') {
        props.handleNewInputValue({name:"newOwnerSignatoryPersonalIdentityNumber", value:props.inputState.newOwnerOrgnr.value})
      }
      setIsPrivate((companyType === 'private'))
    }
  }, [props.inputState.newOwnerOrgnr.value, props.inputState.newOwnerOrgnr.validated])

  /*useEffect(() => {
    if (props.inputState.newOwnerSignatoryNameList.validated) {
      props.handleNewInputValue({name:"newOwnerSignatoryName", value:props.inputState.newOwnerSignatoryNameList.value})
    }
  }, [props.inputState.newOwnerSignatoryNameList.value])*/

  return (
    <div>
      <Header title="Tillträdande adress och kostnadsställe" />
      <div className={styles.inputContainer}>
        <Input
          name="newOwnerOrgnr"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="newOwnerName"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          loading={props.fetchNewOwnerLoading}
        />
        <Input
          name="newOwnerAddressStreet"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          loading={props.fetchNewOwnerLoading}
        />
        <div className={styles.textInputRow}>
          <Input
            name="newOwnerAddressPostalCode"
            inputState={props.inputState}
            onInputChange={props.onInputChange}
            loading={props.fetchNewOwnerLoading}
          />
          <Input
            name="newOwnerAddressCity"
            inputState={props.inputState}
            onInputChange={props.onInputChange}
            loading={props.fetchNewOwnerLoading}
          />
        </div>
        {props.process.type == 'offboarding' && (
          <NewOwnerSignatory
            inputState={props.inputState}
            onInputChange={props.onInputChange}
            signatoryNameListOptions={props.signatoryNameListOptions}
            handleNewInputValue={props.handleNewInputValue}
            hide={isPrivate}
          />
        )}
        {/*<Input
          name="newOwnerSignatoryName"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={props.process.type == 'onboarding' || props.signatoryNameListOptions.length > 0 || isPrivate}
        />
        <Input
          name="newOwnerSignatoryNameList"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          options={props.signatoryNameListOptions}
          hide={props.process.type == 'onboarding' || props.signatoryNameListOptions.length == 0 || isPrivate}
        />
        <Input
          name="newOwnerSignatoryEmail"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={props.process.type == 'onboarding' || isPrivate}
        />
        <Input
          name="newOwnerSignatoryPersonalIdentityNumber"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={props.process.type == 'onboarding' || isPrivate}
  />*/}
      </div>
      {/*<NavigationButtons
        inputState={props.inputState}
        process={props.process}
/>*/}
    </div>
  )
}
  
export default NewOwner