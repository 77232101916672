const getCaseActions = (validatedInputData, condition) => {
  let actions = []
  
  if (condition.isOperatorDiff) {
    actions.push('portation')
  }

  if (condition.isOrgnrDiff && !validatedInputData.vacantNumber) {
    actions.push('transfer')
  }

  if (condition.isOrgnrDiff && validatedInputData.vacantNumber) {
    actions.push('replace')
  }

  if (
    validatedInputData.simCardChoice
    && validatedInputData.simCardChoice == 'esim'
  ) {
    actions.push('esim')
  }

  return actions
}

export default getCaseActions