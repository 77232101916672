const getRequiredDocumentInputs = (documents) => {
  let requiredInputs = []
  documents.map((document) => {
    requiredInputs.push(...document.inputs.filter((input) => {
      return (input.required && !requiredInputs.find((requiredInput) => { return requiredInput.name == input.name }))
    }))
  })
  return requiredInputs
}
  
export default getRequiredDocumentInputs