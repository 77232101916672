import React, { useEffect } from "react"

import styles from "../../../css/MenuItem.module.css"

const MenuItem = props => {

  return (
    <div className={styles.wrapper}>
      <div onClick={() => props.onClickFunction()}>
        {props.text}
      </div>
    </div>
  )
}

export default MenuItem