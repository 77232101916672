import React, { useState, useEffect } from "react"

import Header from "../components/Header"
import TextInput from "../components/TextInput"
import Button from "../components/Button"
import Message from "../components/Message"
import CaseInfo from "../components/CaseInfo"
import ScriveDocuments from "../components/ScriveDocuments"

import useCaseAPI from "../hooks/useCaseAPI"
import useAPI from "../hooks/useAPI"

import pageStyles from "../css/Page.module.css"
import styles from "../css/pages/SearchCase.module.css"

const SearchCase = props => {

  const searchCaseNumber = () => {
    if (caseNumber) {
      setFetchResponseMessage('Hämtar ärendestatus')
      setDoFetchCaseInfo(true)
      setCaseInfo(null)
      setScriveDocuments(null)
    }
  }

  const onCaseNumberChange = e => {
    setCaseNumber(e.target.value)
  }

  const checkFetchResponse = (response, action) => {
    if (response) {
      onFetchResponseRecieved[action](response)
    }
  }

  const onFetchResponseRecieved = {
    caseInfo: (response) => {
      const info = response.info || null
      const responseMessage = response.error || (!info ? 'Ärende kunde inte hittas' : '')
    
      setCaseInfo(info)
      setFetchResponseMessage(responseMessage)
      setDoFetchCaseInfo(false)
    },
    scriveDocuments: (response) => {
      const data = response.documents || null
      const responseMessage = response.error || (!data ? 'Inga dokument hittades' : '')

      setScriveDocuments(data)
      setFetchResponseMessage(responseMessage)
      setDoFetchScriveDocuments(false)
    }
  }

  const [caseNumber, setCaseNumber] = useState('')
  const [scriveDocumentIDs, setScriveDocumentIDs] = useState([])
  const [doFetchCaseInfo, setDoFetchCaseInfo] = useState(false)
  const [doFetchScriveDocuments, setDoFetchScriveDocuments] = useState(false)
  const [fetchResponseMessage, setFetchResponseMessage] = useState('')
  const [caseInfo, setCaseInfo] = useState(null)
  const [scriveDocuments, setScriveDocuments] = useState(null)

  const db = useCaseAPI('on-off-case', 'search', doFetchCaseInfo, {caseNumber:caseNumber})
  const scrive = useAPI('scrive', 'get-documents', doFetchScriveDocuments, {documentIDs:scriveDocumentIDs})

  useEffect(() => {
    checkFetchResponse(db.response, 'caseInfo')
  }, [db.response])

  useEffect(() => {
    checkFetchResponse(scrive.response, 'scriveDocuments')
  }, [scrive.response])

  useEffect(() => {
    if (caseInfo && caseInfo.documents.length > 0) {
      const documentIDs = caseInfo.documents.map((document) => {
        return document.id
      })
      if (documentIDs.length > 0) {
        setScriveDocumentIDs(documentIDs)
        setFetchResponseMessage('Hämtar dokumentstatus')
        setDoFetchScriveDocuments(true)
      }
    }
  }, [caseInfo])

  return (
    <div>
      <Header title='Ärendestatus' />
      <div className={styles.mainContainer}>
        <TextInput
          name='caseNumber'
          value={caseNumber}
          onInputChange={onCaseNumberChange}
          placeholder='Ärendenummer'
        />
        <div className={pageStyles.buttonContainer}>
          <Button
            text='Visa ärendestatus'
            onClickFunction={searchCaseNumber} 
            disabled={db.loading || scrive.loading}
          />
        </div>
        {(caseInfo || scriveDocuments) && (
          <div className="buddy-content-box sorbet">
            {caseInfo && (
              <CaseInfo info={caseInfo.basic} />
            )}
            {scriveDocuments && (
              <ScriveDocuments documents={scriveDocuments} />
            )}
          </div>
        )}
        <Message
          text={fetchResponseMessage}
          loading={(db.loading || scrive.loading)}
          error={(
            ((db.response && db.response.error)
            || (scrive.response && scrive.response.error))
            && !(db.loading || scrive.loading)
          )}
        />
      </div>
    </div>
  )
}

export default SearchCase