import React, { useState, useEffect } from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"
import Message from "../../components/Message"

import styles from "../../css/Page.module.css"

import config from "../../config"

const NumberAction = props => {
  
  const [forceAcceptCloseNumber, setForceAcceptCloseNumber] = useState(false)

  useEffect(() => {
    const force = (
      props.inputState.numberActionChoice.value == 'close'
      || (
        props.inputState.numberActionChoice.value == 'save'
        && props.inputState.closeNumberChoice.value == 'yes'
      )
    )
    setForceAcceptCloseNumber(force)
  }, [props.inputState.numberActionChoice.value, props.inputState.closeNumberChoice.value])

  return (
    <div>
      <Header title="Vad ska ske?" />
      <div className={styles.inputContainer}>
        <Input
          name="numberActionChoice"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="closeNumberChoice"
          title="Om möjligt, skall abonnemanget avslutas?"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(props.inputState.numberActionChoice.value == 'save')}
        />
        <Message
          text={config.message.closeNumberCost}
          hide={!(forceAcceptCloseNumber)}
        />
        <Input
          name="isCloseNumberAccepted"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(forceAcceptCloseNumber)}
        />
      </div>
      <NavigationButtons
        inputState={props.inputState}
        process={props.process}
        condition={props.condition}
        pageTrail={props.pageTrail}
        currentPath={props.currentPath}
        disableForwardNavigation={(
          forceAcceptCloseNumber
          && props.inputState.isCloseNumberAccepted.value === false
        )}
      />
    </div>
  )
}
  
export default NumberAction