import React, { useState, useEffect } from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"

import styles from "../../css/Page.module.css"

const NewNumber = props => {

  return (
    <div>
      <Header title="Vad för nytt nummer?" />
      <div className={styles.inputContainer}>
        <Input
          name="newNumberChoice"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="newOperator"
          title="Välj vilken operatör du vill att numret skall ha"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={(!props.inputState.newNumberChoice.value || props.inputState.newNumberChoice.value == 'reserved')}
        />
        <Input
          name="number"
          placeholder="Ange reserverat nummer"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={(props.inputState.newNumberChoice.value != 'reserved')}
        />
        <Input
          name="currentOperator"
          placeholder="På vilken operatör är numret reserverat?"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={(props.inputState.newNumberChoice.value != 'reserved')}
          loading={props.fetchOperatorLoading}
        />
      </div>
      {/*<NavigationButtons
        inputState={props.inputState}
  />*/}
    </div>
  )
}

export default NewNumber