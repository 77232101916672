import React, { useState, useEffect } from "react"

import config from "../../config"

import Header from "../../components/Header"
import Message from "../../components/Message"

import useCaseAPI from "../../hooks/useCaseAPI"

import styles from "../../css/Summary.module.css"

import getUserEmailInputName from "../../functions/getUserEmailInputName"

const Confirmation = props => {

  const [doFetch, setDoFetch] = useState(false)
  const [email, setEmail] = useState(null)
  const [number, setNumber] = useState(null)
  
  const db = useCaseAPI('on-off-case', 'complete', doFetch, {
    caseID:props.caseID,
    status:props.process.status,
    process:props.process.type,
    caseNumber:props.caseNumber,
    zendeskInfo: {
      email:config.zendesk.email
    },
    email:email,
    number:number
  })

  useEffect(() => {
    const userEmailInputName = getUserEmailInputName(props.process)
    setEmail(props.inputState[userEmailInputName].validated ? props.inputState[userEmailInputName].value : '')
    setNumber(props.inputState.number.validated ? props.inputState.number.value : '')
    if (props.caseID) {
      setDoFetch(true)
    }
  }, [])

  useEffect(() => {
    if (db.response && db.response.error) {
      
    }
    console.log(db.response)
  }, [db.response])

  return (
    <div>
      <Header title="Tack!" />
      <div className={styles.container}>
        <div>
          Dags att luta dig tillbaka.<br />
          När parterna har signerat dokumentet så får dessa ett mail med det signerade dokumentet.
          Vi tar då och skapar ärende hos berörd operatör och hanterar klart ärendet.
          Du kan när som helst följa ditt ärende genom att söka på ditt ärendenummer <a href='https://fixanummer.se/#/search-case' target='_blank'>här</a> men kommer även få mail när det är hanterat.
        </div>
        <Message text={'Ditt ärendenummer är '+props.caseNumber} />
        {email && (
          <Message text={'Ett bekräftelsemail kommer skickas till '+email} />
        )}
        {number && (
          <Message text={'Ett sms kommer skickas till '+number} />
        )}
      </div>
    </div>
  )
}
    
export default Confirmation