import React, { useState, useEffect } from "react"

import Header from "../components/Header"
import Button from "../components/Button"

import styles from "../css/Page.module.css"

import useCaseAPI from "../hooks/useCaseAPI"

const Start = props => {

  const successfulResponse = (response) => {
    if (!response) return false
    setDoFetchStartCase(false)
    if (response.error) {
      setResponseMessage({text:response.error, error:true})
      return false
    }
    return true
  }

  const onFetchStartCaseSuccess = () => {
    props.initializeCase(
      {
        id: fetchStartCase.response.id,
        caseNumber: fetchStartCase.response.caseNumber,
        process: caseToStart.process,
        status: fetchStartCase.response.status,
        inputValues: fetchStartCase.response.inputValues
      },
      '/start-'+caseToStart.process
    )
  }

  const startOnboardingProcess = () => {
    startCase('onboarding')
  }
  const startOffboardingProcess = () => {
    startCase('offboarding')
  }
  
  const startCase = (process) => {
    setCaseToStart({
      process: process,
      companyID: props.company.id || 0,
      inputValues: getInputValues()
    })
    setDoFetchStartCase(true)
  }

  const getInputValues = () => {
    let inputValues = {}
    if (props.company.basic) {
      inputValues.orgnr = props.company.basic.orgnr || ''
    }
    if (props.company.policy) {
      const companyPolicy = {...props.company.policy}
      delete companyPolicy.id
      inputValues = {...inputValues, ...companyPolicy}
    }
    return inputValues
  }

  const [doFetchStartCase, setDoFetchStartCase] = useState(false)
  const [caseToStart, setCaseToStart] = useState({})
  const [responseMessage, setResponseMessage] = useState({text:'', error:false})

  const fetchStartCase = useCaseAPI('on-off-case', 'start', doFetchStartCase, caseToStart, props.accessToken)

  useEffect(() => {
    if (!successfulResponse(fetchStartCase.response)) return
    onFetchStartCaseSuccess()
  }, [fetchStartCase.loading])

  return (
    <div>
      <Header title="Vad vill du göra?"/>
      <div className={styles.buttonContainer}>
        <Button
          text="Onboarding"
          onClickFunction={startOnboardingProcess}
          disabled={fetchStartCase.loading && caseToStart.process === 'onboarding'}
          loading={fetchStartCase.loading && caseToStart.process === 'onboarding'}
        />
        <Button
          text="Offboarding"
          onClickFunction={startOffboardingProcess}
          disabled={fetchStartCase.loading && caseToStart.process === 'offboarding'}
          loading={fetchStartCase.loading && caseToStart.process === 'offboarding'}
        />
        <Button text="Sök ärende" navigateTo="search-case" />
        {/*!props.isManagerLoggedIn && (
          <Button text="Logga in med Bankid" navigateTo="login" />
        )*/}
        {props.isManagerLoggedInWithCompany && (
          <Button text="Företagsprofil" navigateTo="company-profile" />
        )}
      </div>
    </div>
  )
}

export default Start