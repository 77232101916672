import React from "react"

import styles from "../css/Message.module.css"

const Message = props => {

  const weight = props.weight || 'normal'

  let classNames = [styles.text, styles[weight]]
  
  if (props.loading) {
    classNames.push(styles.loading)
  }
  if (props.error) {
    classNames.push(styles.error)
  }
  if (props.hide) {
    classNames.push(styles.hidden)
  }
  
  return (
    <div className={classNames.join(" ")}>{props.text}</div>
  )
}

export default Message