import React, {useState, useEffect} from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"
import NewOwnerSignatory from "../../components/NewOwnerSignatory"
import CurrentOwnerSignatory from "../../components/CurrentOwnerSignatory"

import styles from "../../css/Page.module.css"

const CaseManager = props => {

  /*useEffect(() => {
    if (props.inputState.caseManagerIsSignatory.value == 'yes') {
      if (props.inputState.caseManagerName.validated) {
        props.handleNewInputValue({name:"newOwnerSignatoryName", value:props.inputState.caseManagerName.value})
      }
      if (props.inputState.caseManagerEmail.validated) {
        props.handleNewInputValue({name:"newOwnerSignatoryEmail", value:props.inputState.caseManagerEmail.value})
      }
      if (props.inputState.caseManagerPersonalIdentityNumber.validated) {
        props.handleNewInputValue({name:"newOwnerSignatoryPersonalIdentityNumber", value:props.inputState.caseManagerPersonalIdentityNumber.value})
      }
    }
    else if (props.inputState.caseManagerIsSignatory.value == 'no') {
      if (props.inputState.newOwnerSignatoryNameList.validated) {
        props.handleNewInputValue({name:"newOwnerSignatoryName", value:props.inputState.newOwnerSignatoryNameList.value})
      }
    }
  }, [
    props.inputState.caseManagerName.validated,
    props.inputState.caseManagerEmail.validated,
    props.inputState.caseManagerPersonalIdentityNumber.validated,
    props.inputState.newOwnerSignatoryNameList.value,
    props.inputState.caseManagerIsSignatory.value
  ])*/

  return (
    <div>
      <Header title="Namn och kontaktinfo till ärendehanteraren" />
      <div className={styles.inputContainer}>
        <Input
          name="caseManagerName"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="caseManagerNumber"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="caseManagerEmail"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="caseManagerIsSignatory"
          title="Vem är firmatecknare?"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="caseManagerPersonalIdentityNumber"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(props.inputState.caseManagerIsSignatory.value == 'yes')}
        />
        {props.process.type == 'onboarding' && (
          <NewOwnerSignatory
            inputState={props.inputState}
            onInputChange={props.onInputChange}
            signatoryNameListOptions={props.signatoryNameListOptions.newOwner}
            handleNewInputValue={props.handleNewInputValue}
            hide={!(props.inputState.caseManagerIsSignatory.value == 'no')}
          />
        )}
        {props.process.type == 'offboarding' && (
          <CurrentOwnerSignatory
            inputState={props.inputState}
            onInputChange={props.onInputChange}
            signatoryNameListOptions={props.signatoryNameListOptions.currentOwner}
            handleNewInputValue={props.handleNewInputValue}
            hide={!(props.inputState.caseManagerIsSignatory.value == 'no')}
          />
        )}
        {/*<Input
          name="newOwnerSignatoryName"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={(props.inputState.caseManagerIsSignatory.value != 'no') || props.signatoryNameListOptions.length > 0}
        />
        <Input
          name="newOwnerSignatoryNameList"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          options={props.signatoryNameListOptions}
          hide={(props.inputState.caseManagerIsSignatory.value != 'no') || props.signatoryNameListOptions.length == 0}
        />
        <Input
          name="newOwnerSignatoryEmail"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(props.inputState.caseManagerIsSignatory.value == 'no')}
        />
        <Input
          name="newOwnerSignatoryPersonalIdentityNumber"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(props.inputState.caseManagerIsSignatory.value == 'no')}
  />*/}
      </div>
      {/*<NavigationButtons
        inputState={props.inputState}
/>*/}
    </div>
  )
}
  
export default CaseManager