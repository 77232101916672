const selectOptions = {
    operators: [
        {
            value: '',
            label: 'Välj operatör'
        },
        {
            value: 'Telia',
            label: 'Telia'
        },
        {
            value: 'Telenor',
            label: 'Telenor'
        },
        {
            value: '3',
            label: '3'
        },
        {
            value: 'Tele2',
            label: 'Tele2'
        },
        {
          value: 'Telavox',
          label: 'Telavox'
        }
    ]
}

const inputs = [
    {
        type: 'text',
        name: 'orgnr',
        placeholder: 'Företagets organisationsnummer',
        validateAs: 'orgnr',
        errorMessage: 'Organisationsnummer är ej korrekt ifyllt'
    },
    {
        type: 'text',
        name: 'newUserName',
        placeholder: 'Namn på ny användare',
        validateAs: 'name',
        errorMessage: 'Namn är ej korrekt ifyllt'
    },
    {
        type: 'text',
        name: 'newUserEmail',
        placeholder: 'E-postadress till användaren',
        validateAs: 'email',
        errorMessage: 'E-postadress är ej korrekt ifylld'
    },
    {
        type: 'text',
        name: 'newUserAddressStreet',
        placeholder: 'Gatuadress',
        validateAs: 'street'
    },
    {
        type: 'text',
        name: 'newUserAddressPostalCode',
        placeholder: 'Postnummer',
        validateAs: 'postalCode'
    },
    {
        type: 'text',
        name: 'newUserAddressCity',
        placeholder: 'Postort',
        validateAs: 'city'
    },
    {
        type: 'text',
        name: 'currentUserName',
        placeholder: 'Namn på befintlig användare',
        validateAs: 'name',
        errorMessage: 'Namn är ej korrekt ifyllt'
    },
    {
        type: 'text',
        name: 'currentUserEmail',
        placeholder: 'E-postadress till användaren',
        validateAs: 'email',
        errorMessage: 'E-postadress är ej korrekt ifylld'
    },
    {
        type: 'text',
        name: 'startDate',
        placeholder: 'Startdatum, lämna tomt om snarast',
        validateAs: 'date'
    },
    {
        type: 'text',
        name: 'endDate',
        placeholder: 'Slutdatum, lämna tomt om snarast',
        validateAs: 'date'
    },
    {
        type: 'text',
        name: 'number',
        placeholder: 'Nummer',
        validateAs: 'number'
    },
    {
        type: 'text',
        name: 'vacantNumber',
        placeholder: 'Nummer',
        validateAs: 'number'
    },
    {
        type: 'text',
        name: 'currentOwnerOrgnr',
        placeholder: 'Orgnr',
        validateAs: 'orgnr'
    },
    {
        type: 'text',
        name: 'currentOwnerName',
        placeholder: 'Namn',
        validateAs: 'notEmpty'
    },
    {
        type: 'text',
        name: 'currentOwnerAddressStreet',
        placeholder: 'Gatuadress',
        validateAs: 'street'
    },
    {
        type: 'text',
        name: 'currentOwnerAddressPostalCode',
        placeholder: 'Postnummer',
        validateAs: 'postalCode'
    },
    {
        type: 'text',
        name: 'currentOwnerAddressCity',
        placeholder: 'Postort',
        validateAs: 'city'
    },
    {
        type: 'text',
        name: 'currentOwnerSignatoryName',
        placeholder: 'Firmatecknare',
        validateAs: 'notEmpty'
    },
    {
        type: 'text',
        name: 'currentOwnerSignatoryEmail',
        placeholder: 'Firmatecknarens e-post',
        validateAs: 'email'
    },
    {
        type: 'text',
        name: 'currentOwnerSignatoryPersonalIdentityNumber',
        placeholder: 'Firmatecknarens personnummer',
        validateAs: 'personalIdentityNumber'
    },
    {
        type: 'select',
        name: 'currentOwnerSignatoryNameList',
        options: [],
        validateAs: 'notEmpty'
    },
    {
        type: 'text',
        name: 'desiredDataPlan',
        placeholder: 'Önskad surfmängd',
        validateAs: 'notEmpty',
        errorMessage: 'Önskad surfmängd är ej korrekt ifylld'
    },
    {
        type: 'text',
        name: 'newOwnerOrgnr',
        placeholder: 'Orgnr',
        validateAs: 'orgnr'
    },
    {
      type: 'text',
      name: 'newOwnerName',
      placeholder: 'Namn',
      validateAs: 'notEmpty'
    },
    {
        type: 'text',
        name: 'newOwnerCostCenter',
        placeholder: 'Kostnadsställe',
        validateAs: 'notEmpty'
    },
    {
        type: 'text',
        name: 'newOwnerAddressStreet',
        placeholder: 'Gatuadress',
        validateAs: 'street'
    },
    {
        type: 'text',
        name: 'newOwnerAddressPostalCode',
        placeholder: 'Postnummer',
        validateAs: 'postalCode'
    },
    {
        type: 'text',
        name: 'newOwnerAddressCity',
        placeholder: 'Postort',
        validateAs: 'city'
    },
    {
        type: 'text',
        name: 'newOwnerSignatoryName',
        placeholder: 'Firmatecknare',
        validateAs: 'name'
    },
    {
        type: 'text',
        name: 'newOwnerSignatoryEmail',
        placeholder: 'Firmatecknarens e-post',
        validateAs: 'email'
    },
    {
        type: 'text',
        name: 'newOwnerSignatoryPersonalIdentityNumber',
        placeholder: 'Firmatecknarens personnummer',
        validateAs: 'personalIdentityNumber'
    },
    {
        type: 'select',
        name: 'newOwnerSignatoryNameList',
        options: [],
        validateAs: 'notEmpty'
    },
    {
        type: 'text',
        name: 'caseManagerName',
        placeholder: 'Namn',
        validateAs: 'name'
    },
    {
        type: 'text',
        name: 'caseManagerNumber',
        placeholder: 'Nummer',
        validateAs: 'number'
    },
    {
        type: 'text',
        name: 'caseManagerEmail',
        placeholder: 'E-post',
        validateAs: 'email'
    },
    {
        type: 'text',
        name: 'caseManagerPersonalIdentityNumber',
        placeholder: 'Personnummer',
        validateAs: 'personalIdentityNumber'
    },
    {
        type: 'radio',
        name: 'caseManagerIsSignatory',
        options: [
            {
              value: 'yes',
              label: 'Jag'
            },
            {
              value: 'no',
              label: 'Annan'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'text',
        name: 'currentOperator',
        placeholder: 'Operatör',
        validateAs: 'notEmpty',
        errorMessage: 'Nuvarande operatör är ej korrekt ifylld'
    },
    {
        type: 'select',
        name: 'newOperator',
        placeholder: 'Operatör',
        options: selectOptions.operators,
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'simCardChoice',
        options: [
            {
              value: 'owned',
              label: 'Nej, vi har'
            },
            {
              value: 'send',
              label: 'Ja, skicka ut'
            },
            {
              value: 'esim',
              label: 'Ja, E-sim'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'simCardSendChoice',
        options: [
            {
              value: 'company',
              label: 'Företagsadressen'
            },
            {
              value: 'user',
              label: 'Användaradressen'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'text',
        name: 'simCardIcc',
        placeholder: 'Skriv in SIM-kortsnummer',
        validateAs: 'icc'
    },
    {
        type: 'radio',
        name: 'numberChoice',
        options: [
            {
              value: 'new',
              label: 'Behöver ett nytt nummer'
            },
            {
              value: 'other',
              label: 'Numret står på ett annat företag/person'
            },
            {
              value: 'existing',
              label: 'Har ett befintligt nummer på företaget'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'newNumberChoice',
        options: [
            {
              value: 'new',
              label: 'Skapa ett nytt nummer'
            },
            {
              value: 'reserved',
              label: 'Har ett nummer reserverat'
            },
            {
              value: 'vacant',
              label: 'Har vakant abonnemang men kan ej numret'
            },
            {
              value: 'series',
              label: 'Ta från befintlig nummerserie'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'dataPlanChoice',
        options: [
            {
              value: 'unchanged',
              label: 'Oförändrad'
            },
            {
              value: 'unlimited',
              label: 'Obegränsat'
            },
            {
              value: 'other',
              label: 'Ange önskad GB förbrukning!'
            }
        ],
        validateAs: 'notEmpty',
        errorMessage: 'Surfmängd är ej vald'
    },
    {
        type: 'radio',
        name: 'replaceExistingUserChoice',
        options: [
            {
              value: 'no',
              label: 'Nej'
            },
            {
              value: 'yes',
              label: 'Ja'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'changeDataPlanChoice',
        options: [
            {
              value: 'no',
              label: 'Behåll befintlig'
            },
            {
              value: 'decrease',
              label: 'Minska'
            },
            {
              value: 'increase',
              label: 'Öka'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'moveNumberChoice',
        options: [
            {
              value: 'none',
              label: 'Ingen flytt, endast justering'
            },  
            {
              value: 'both',
              label: 'Både nummer och abonnemang'
            },
            {
              value: 'number',
              label: 'Endast numret'
            },
            {
              value: 'subscription',
              label: 'Endast abonnemanget'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'hasSubscriptionChoice',
        options: [
            {
              value: 'yes',
              label: 'Ja'
            },
            {
              value: 'no',
              label: 'Nej'
            },
            {
              value: 'unknown',
              label: 'Vet ej'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'finalInvoiceCostChoice',
        options: [
            {
              value: 'current',
              label: 'Frånlämnande org/person'
            },
            {
              value: 'new',
              label: 'Tillträdande org'
            },
            {
              value: 'unknown',
              label: 'Ännu oklart'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'newSubscriptionChoice',
        options: [
            {
              value: 'new',
              label: 'På ett nytt abonnemang'
            },
            {
              value: 'vacant',
              label: 'På vakant abonnemang'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'numberActionChoice',
        options: [
            {
              value: 'user',
              label: 'Numret skall användas till annan användare'
            },
            {
              value: 'transfer',
              label: 'Numret skall överlåtas till användaren som slutar'
            },
            {
              value: 'save',
              label: 'Numret skall reserveras på företaget'
            },
            {
              value: 'close',
              label: 'Numret ska avslutas'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'subscriptionActionChoice',
        options: [
            {
              value: 'close',
              label: 'Abonnemanget skall om möjligt avslutas'
            },
            {
              value: 'transfer',
              label: 'Abonnemanget skall överlåtas'
            },
            {
              value: 'vacant',
              label: 'Abonnemanget skall läggas vakant (med annat nummer)'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'closeNumberChoice',
        options: [
            {
              value: 'no',
              label: 'Nej'
            },
            {
              value: 'yes',
              label: 'Ja'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'radio',
        name: 'divertNumberChoice',
        options: [
            {
              value: 'no',
              label: 'Nej'
            },
            {
              value: 'yes',
              label: 'Ja'
            }
        ],
        validateAs: 'notEmpty'
    },
    {
        type: 'text',
        name: 'divertToNumber',
        placeholder: 'Nummer',
        validateAs: 'number'
    },
    {
        type: 'checkbox',
        name: 'isUserDocumentViewer',
        label: 'Lägg till användaren som övervakare',
        validateAs: 'boolean'
    },
    {
        type: 'checkbox',
        name: 'isGeneralAgreementAccepted',
        label: 'Jag accepterar de allmänna villkoren',
        validateAs: 'boolean'
    },
    {
        type: 'checkbox',
        name: 'isCloseNumberAccepted',
        label: 'Accepteras',
        validateAs: 'boolean'
    }
]

const listFunctions = {
  getInput: (name) => {
    const input = inputs.find(input => {
      return input.name === name
    })
    return input
  }
}

export default inputs
export { listFunctions }