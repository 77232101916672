import React, { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"

import Main from "./Main"
import PoweredBy from "./PoweredBy"
import Start from "../pages/Start"
import SearchCase from "../pages/SearchCase"
import Login from "../pages/Login"
import Logout from "../pages/Logout"
import OpenCompany from "../pages/OpenCompany"
import CompanyProfile from "../pages/CompanyProfile"
import ManagerPanel from "./ManagerPanel/ManagerPanel"

import detectPoweredByOverlap from "../functions/detectPoweredByOverlap"

import config from "../config"

const AppContainer = () => {

  const navigate = useNavigate()

  const logOut = () => {
    setManager({})
    setCompany({})
    setCurrentCase({})
    setIsManagerLoggedInWithCompany(false)
    setIsManagerLoggedIn(false)
    setAccessToken('')
    navigate('/')
  }

  const initializeCase = (newCase, startPath) => {
    setCurrentCase(newCase)
    setProcess({type:newCase.process, status:newCase.status})
    navigate(startPath)
    //setCaseInitialized(true)
  }

  const test = () => {
    console.log(accessToken)
    console.log(manager)
    console.log(company)
    console.log(currentCase)
    console.log(isManagerLoggedIn)
    console.log(isManagerLoggedInWithCompany)
  }

  const [isManagerLoggedIn, setIsManagerLoggedIn] = useState(false)
  const [isManagerLoggedInWithCompany, setIsManagerLoggedInWithCompany] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const [manager, setManager] = useState({})
  const [company, setCompany] = useState({})
  const [process, setProcess] = useState({type: '', status: null})
  const [currentCase, setCurrentCase] = useState({})
  const [poweredByOverlap, setPoweredByOverlap] = useState()
  //const [caseInitialized, setCaseInitialized] = useState(false)

  const updateStatus = (newStatus) => {
    setProcess(previousProcess => ({
      ...previousProcess,
      status: newStatus
    }))
  }

  /*useEffect(() => {
    if (caseInitialized && process.type && process.status && Object.keys(currentCase).length > 0) {
      navigate('/start-'+process.type)
      setCaseInitialized(false)
    }
  }, [caseInitialized, process, currentCase])*/

  useEffect(() => {
    setAccessToken(sessionStorage.getItem(config.storageKey.accessToken) || '')
  }, [])

  useEffect(() => {
    sessionStorage.setItem(config.storageKey.accessToken, accessToken)
    setIsManagerLoggedIn(accessToken.length > 0)
  }, [accessToken])

  useEffect(() => {
    if (isManagerLoggedIn && Object.keys(company).length === 0) {
      navigate('/open-company')
    }
    setIsManagerLoggedInWithCompany((isManagerLoggedIn && Object.keys(company).length > 0))
  }, [isManagerLoggedIn, company])

  useEffect(() => {
    setPoweredByOverlap(detectPoweredByOverlap())
  }, [])

  return (
    <div id="main-app-container" className="app-container">
      {isManagerLoggedIn && (
        <ManagerPanel
          manager={manager}
          companyInfo={company.basic || {}}
          isManagerLoggedInWithCompany={isManagerLoggedInWithCompany}
        />
      )}
      {/*<div><a onClick={() => test()}>Test</a></div>*/}
      <Routes>
        <Route path="/" element={
          <Start
            isManagerLoggedIn={isManagerLoggedIn}
            isManagerLoggedInWithCompany={isManagerLoggedInWithCompany}
            initializeCase={initializeCase}
            setProcess={setProcess}
            accessToken={accessToken}
            company={company}
          />
        } />
        <Route path="*" element={
          <Main
            company={company}
            case={currentCase}
            process={process}
            updateStatus={updateStatus}
          />
        } />
        <Route path="/search-case" element={<SearchCase />} />
        <Route path="/login" element={<Login setAccessToken={setAccessToken} setManager={setManager} />} />
        <Route path="/logout" element={<Logout logOut={logOut} />} />
        {isManagerLoggedIn && (
          <Route path="/open-company" element={
            <OpenCompany
              setCompany={setCompany}
              accessToken={accessToken}
            />
          } />
        )}
        {isManagerLoggedInWithCompany && (
          <Route path="/company-profile" element={
            <CompanyProfile
              company={company}
              setCompany={setCompany}
              initializeCase={initializeCase}
              setProcess={setProcess}
              accessToken={accessToken}
            />
          } />
        )}
      </Routes>
      <div>
        <PoweredBy overlap={poweredByOverlap} />
      </div>
    </div>
  )
}

export default AppContainer