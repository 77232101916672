import React, { useState, useEffect } from "react"

import styles from "../../../css/Page.module.css"

import Policy from "./Policy"

const PolicyTab = props => {

  const updateCompanyPolicy = (newPolicy) => {
    props.setCompany(previousData  => ({...previousData, policy: newPolicy}))
  }

  let classNames = []
  if (props.hide) {
    classNames.push(styles.hidden)
  }

  return(
    <div className={classNames.join(" ")}>
      <div className={styles.itemContainer}>
        <Policy
          companyID={props.companyID}
          data={props.policy}
          updateCompanyPolicy={updateCompanyPolicy}
          //successfulResponse={props.successfulResponse}
          //updateResponseMessage={props.updateResponseMessage}
          accessToken={props.accessToken}
        />
      </div>
    </div>
  )
}
  
export default PolicyTab