import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import Header from "../components/Header"
import Button from "../components/Button"

import styles from "../css/Page.module.css"

import useManagerAPI from "../hooks/useManagerAPI"

const Login = props => {

  const navigate = useNavigate()

  const startBankIDApp = () => {
    if (autoStartToken) {
      window.location.replace('bankid:///?autostarttoken='+autoStartToken+'&redirect=null')
    }
  }

  const onLogInSuccess = () => {
    props.setAccessToken(logIn.response.accessToken)
    props.setManager(logIn.response.manager)
    navigate('/') // navigate to administartor company list?
  }

  const successfulResponse = (response) => {
    if (!response) return false
    if (response.error) {
      setError(response.error)
      return false
    }
    return true
  }

  const [autoStartToken, setAutoStartToken] = useState('')
  const [orderRef, setOrderRef] = useState('')
  const [error, setError] = useState('')
  const [doFetchCreateOrder, setDoFetchCreateOrder] = useState(false)
  const [doFetchLogIn, setDoFetchLogIn] = useState(false)

  const createOrder = useManagerAPI('authentication', 'create-order', doFetchCreateOrder, {}, '')
  const logIn = useManagerAPI('authentication', 'log-in', doFetchLogIn, {orderRef:orderRef}, '')

  useEffect(() => {
    setDoFetchCreateOrder(true)
  }, [])

  useEffect(() => {
    if (!successfulResponse(createOrder.response)) return
    setAutoStartToken(createOrder.response.autoStartToken)
    setOrderRef(createOrder.response.orderRef)
  }, [createOrder.loading])

  useEffect(() => {
    if (!autoStartToken) return
    startBankIDApp()
    // enable open bank id app button
  }, [autoStartToken])

  useEffect(() => {
    if (!orderRef) return
    console.log(orderRef)
    setDoFetchLogIn(true)
  }, [orderRef])

  useEffect(() => {
    if (!successfulResponse(logIn.response)) return
    console.log(logIn.response)
    onLogInSuccess()
  }, [logIn.loading])

  return (
    <div>
      <Header title="Logga in med bankid" />
      <div className={styles.loadSpin}></div>
      <div className={styles.buttonContainer}>
        <Button
          text="Öppna Bankid-app"
          onClickFunction={startBankIDApp}
          disabled={(!autoStartToken)}
        />
      </div>
    </div>
  )
}

export default Login