import React, { useState, useEffect } from "react"

import Case from "./Case"

import styles from "../../../css/Page.module.css"

const CaseTab = props => {

  let classNames = []
  if (props.hide) {
    classNames.push(styles.hidden)
  }

  return(
    <div className={classNames.join(" ")}>
      <div className={styles.separatedItemContainer}>
        {props.caseList.map((caseInfo, index) => (
          <Case
            data={caseInfo} 
            dataIndex={index}
            initializeCase={props.initializeCase}
            setProcess={props.setProcess}
            accessToken={props.accessToken}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}

export default CaseTab