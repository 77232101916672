import React from "react"

import styles from "../css/TableList.module.css"

const TableList = props => {

  return (
    <div className={styles.container}>
      <table>
        <tbody>
          {props.items.map((item, index) => (
            <tr key={index}>
              <td>{item.title}:</td>
              <td>{item.text}</td>
            </tr>  
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableList