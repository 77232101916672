import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import Header from "../components/Header"
import Message from "../components/Message"
import Button from "../components/Button"

import CompanyList from "../components/open-company/CompanyList"
import NewCompany from "../components/open-company/NewCompany"

import styles from "../css/Page.module.css"

import useManagerAPI from "../hooks/useManagerAPI"

const OpenCompany = props => {

  const navigate = useNavigate()

  /*const updateResponseMessage = props => {
    const text = props.text || ''
    const error = props.error || false
    const loading = props.loading || false
    setResponseMessage({text:text, error:error, loading:loading})
  }*/

  /*const successfulResponse = (response) => {
    if (!response) return false
    if (response.error) {
      updateResponseMessage({text:response.error, error:true})
      return false
    }
    return true
  }*/

  const successfulResponse = (response) => {
    if (!response) return false
    if (response.error) {
      setResponseMessage({text:response.error, error:true})
      return false
    }
    return true
  }

  const onFetchCompanyListSuccess = () => {
    setResponseMessage({text:'', error:false})
    setCompanyList(fetchCompanyList.response.list)
  }

  const onFetchCompanySuccess = () => {
    props.setCompany(fetchCompany.response)
    navigate('/')
  } 

  const [companyID, setCompanyID] = useState(null)
  const [companyList, setCompanyList] = useState([])
  const [doFetchCompanyList, setDoFetchCompanyList] = useState(false)
  const [doFetchCompany, setDoFetchCompany] = useState(false)
  const [responseMessage, setResponseMessage] = useState({})

  const fetchCompanyList = useManagerAPI('manager', 'get-company-list', doFetchCompanyList, {}, props.accessToken)
  const fetchCompany = useManagerAPI('company', 'get-info', doFetchCompany, {id:companyID}, props.accessToken)

  useEffect(() => {
    setDoFetchCompanyList(true)
    setResponseMessage({text:'Hämtar företag', error:false})
  }, [])

  useEffect(() => {
    if (!companyID) return
    setDoFetchCompany(true)
    setResponseMessage({text:'Öppnar företag', error:false})
  }, [companyID])

  useEffect(() => {
    if (!successfulResponse(fetchCompanyList.response)) return
    onFetchCompanyListSuccess()
  }, [fetchCompanyList.loading])

  useEffect(() => {
    if (!successfulResponse(fetchCompany.response)) return
    onFetchCompanySuccess()
  }, [fetchCompany.loading])

  return (
    <div>
      <Header title='Företag' />
      <div className={styles.itemContainer}>
        {companyList.length > 0 && (
          <CompanyList
            list={companyList}
            setCompanyID={setCompanyID}
          />
        )}
        <NewCompany
          setCompanyID={setCompanyID}
          //updateResponseMessage={updateResponseMessage}
          //successfulResponse={successfulResponse}
          accessToken={props.accessToken}
        />
      </div>
      <Message
        text={responseMessage.text}
        error={responseMessage.error}
        loading={fetchCompanyList.loading || fetchCompany.loading}
      />
    </div>
  )
}

export default OpenCompany