import React, { useState, useEffect } from "react"

import Title from "../Title"
import Button from "../Button"
import Message from "../Message"
import RadioInputGroup from "../RadioInputGroup"

import validateValue from "../../functions/validateValue"

import styles from "../../css/Page.module.css"

const CompanyList = props => {

  const onInputChange = e => {
    setData(previousData  => ({...previousData, [e.target.name]: e.target.value}))
    console.log(data)
  }

  const validateData = () => {
    if (!validateValue(data.companyChoice, 'notEmpty')) {
      setResponseMessage({text:'Inget företag är valt', error:true})
      return false
    } 
    return true
  }

  const openCompany = () => {
    //console.log(data.companyChoice, validateValue(data.companyChoice, 'notEmpty'))
    if (!validateData()) return
    setResponseMessage({text:'', error:false})
    props.setCompanyID(data.companyChoice)
  }

  const [data, setData] = useState({})
  const [responseMessage, setResponseMessage] = useState({})

  const options = props.list.map((listItem) => {
    return {value:listItem.id, label:listItem.orgnr+' - '+listItem.name}
  })

  return (
    <div>
      <Title text='Välj företag' />
      <div className={styles.inputContainer}>
        <RadioInputGroup
          name='companyChoice'
          value={data.companyChoice || ''}
          onInputChange={onInputChange}
          options={options}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button text='Öppna företag' onClickFunction={openCompany} /> 
      </div>
      <Message
        text={responseMessage.text}
        error={responseMessage.error}
      />
    </div>
  )
}

export default CompanyList