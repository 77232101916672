import React, { useEffect } from "react"

import { listFunctions as inputListFunctions } from "../../lists/inputs";

import Text from "./types/Text/Text"
import RadioGroup from "./types/Radio/RadioGroup"
import Select from "./types/Select/Select"
import Checkbox from "./types/Checkbox/Checkbox"

import styles from "../../css/Input.module.css"

const Input = props => {
  const getTitle = inputTitle => {
    const title = inputTitle ? <div className={styles.title}>{inputTitle}</div> : ''
    return title
  }

  const handleErrorMessage = (input) => {
    const action = (!props.inputState[input.name].validated && !props.optional) ? 'add' : 'remove'
    props.setErrorMessages(previousErrorMessages => {
      const errorMessageIndex = previousErrorMessages.indexOf(input.errorMessage)
      if (action == 'remove' && errorMessageIndex > -1) {
        previousErrorMessages.splice(errorMessageIndex, 1)
      }
      else if (action == 'add' && errorMessageIndex == -1) {
        previousErrorMessages.push(input.errorMessage)
      }
      return previousErrorMessages
    })
  }

  const getComponent = (props) => {

  }
  
  const input = inputListFunctions.getInput(props.name)
  const title = getTitle(props.title)
  //const component = getComponent()

  let classNames = []
  if (props.visibility == 'hidden') {
    classNames.push(styles.hidden)
  }
  if (props.hide) {
    classNames.push(styles.hidden)
  }
  
  const options = props.options || input.options || []
  const placeholder = props.placeholder || input.placeholder || ''
  const label = props.label || input.label || ''

  let component
  switch(input.type) {
    case 'text':
      component = <Text
        placeholder={placeholder}
        name={input.name}
        inputState={props.inputState}
        onInputChange={props.onInputChange}
        loading={props.loading}
        tooltip={props.tooltip}
      />
      break
    case 'radio':
      component = <RadioGroup
        name={input.name}
        options={options}
        inputState={props.inputState}
        onInputChange={props.onInputChange}
        optionsToHide={props.optionsToHide}
      />
      break
    case 'select':
      component = <Select
        placeholder={placeholder}
        name={input.name}
        options={options}
        inputState={props.inputState}
        onInputChange={props.onInputChange}
      />
      break
    case 'checkbox':
      component = <Checkbox
        label={label}
        name={input.name}
        inputState={props.inputState}
        onInputChange={props.onInputChange}
      />
      break
    default:
      component = <div></div>
      break
  }

  useEffect(() => {
    if (props.setErrorMessages) {
      handleErrorMessage(input)
    }
  }, [props.inputState[input.name].validated, props.optional])

  return (
    <div className={classNames.join(" ")}>
      {title}
      {component}
    </div>
  )
}

export default Input