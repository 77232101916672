const getValidationPattern = (validateAs) => {
  return patternDictionary[validateAs] || null
}

const patternDictionary = {
  notEmpty: new RegExp(/(.|\s)*\S(.|\s)*/),
  notUndefined: new RegExp(/^((?!undefined).)*$/),
  number: new RegExp(/^(07|467|\+467)(\s?-?\d){8}$/),
  email: new RegExp(/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/),
  orgnr: new RegExp(/^\d{6}[-]?\d{4}$/), // new RegExp(/^([5]\d{5}[-]\d{4})|([5]\d{9})$/)
  personalIdentityNumber: new RegExp(/^(\d{6}|\d{8})[-]?\d{4}$/),
  date: new RegExp(/^[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/),
  name: new RegExp(/^([A-Za-z]|Å|Ä|Ö|É|å|ä|ö|é|-|,){2,}(\s([A-Za-z]|Å|Ä|Ö|É|å|ä|ö|é|-|,){2,})+$/),
  postalCode: new RegExp(/^(\d{5})|(\d{3}\s\d{2})$/),
  street: new RegExp(/^([A-Za-z]|Å|Ä|Ö|É|å|ä|ö|é){2,}(\s([A-Za-z0-9]|Å|Ä|Ö|É|å|ä|ö|é)+)+$/),
  city: new RegExp(/^([A-Za-z]|Å|Ä|Ö|É|å|ä|ö|é){2,}(\s([A-Za-z]|Å|Ä|Ö|É|å|ä|ö|é){2,})*$/),
  icc: new RegExp(/^\d{10,}$/),
  boolean: new RegExp(/^(true|false)$/)
}

export default getValidationPattern