import config from "../config"

const changePage = (navigateTo, navigate) => {
  switch (navigateTo) {
    case 'previous': {
      navigate((-1))
      break
    }
    default: {
      navigate('/'+navigateTo)
      break
    }
  }
  sessionStorage.setItem(config.storageKey.navigatedTo, navigateTo)
}

export default changePage