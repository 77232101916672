import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import Header from "../components/Header"

import styles from "../css/Page.module.css"

import useManagerAPI from "../hooks/useManagerAPI"

const Logout = props => {

  const navigate = useNavigate()

  const successfulResponse = (response) => {
    if (!response) return false
    if (response.error) {
      setError(response.error)
      return false
    }
    return true
  }

  const onLogOutSuccess = () => {
    props.logOut()
  }

  const [error, setError] = useState('')
  const [doFetchLogOut, setDoFetchLogOut] = useState(false)

  const logOut = useManagerAPI('authentication', 'log-out', doFetchLogOut, {}, '')

  useEffect(() => {
    setDoFetchLogOut(true)
  }, [])

  useEffect(() => {
    if (!successfulResponse(logOut.response)) return
    onLogOutSuccess()
  }, [logOut.loading])

  return (
    <div>
      <Header title="Du loggas nu ut" />
      <div className={styles.loadSpin}></div>
    </div>
  )
}

export default Logout