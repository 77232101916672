import React, { useState, useEffect } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"

import NavigationBar from "./NavigationBar"
import NavigationHandler from "./NavigationHandler"
import NavigationButtons from "./NavigationButtons"

import StartOnboarding from "../pages/main/StartOnboarding"
import StartOffboarding from "../pages/main/StartOffboarding"
import Number from "../pages/main/Number"
import NewNumber from "../pages/main/NewNumber"
import ReplaceExistingUser from "../pages/main/ReplaceExistingUser"
import ChangeDataPlan from "../pages/main/ChangeDataPlan"
import MoveNumber from "../pages/main/MoveNumber"
import DataPlan from "../pages/main/DataPlan"
import SimCard from "../pages/main/SimCard"
import HasSubscription from "../pages/main/HasSubscription"
import FinalInvoice from "../pages/main/FinalInvoice"
import CurrentOwner from "../pages/main/CurrentOwner"
import NewSubscription from "../pages/main/NewSubscription"
import NewOwner from "../pages/main/NewOwner"
import CaseManager from "../pages/main/CaseManager"
import NumberAction from "../pages/main/NumberAction"
import SubscriptionAction from "../pages/main/SubscriptionAction"
import DivertNumber from "../pages/main/DivertNumber"
import NewUser from "../pages/main/NewUser"
import Summary from "../pages/main/Summary"
import Confirmation from "../pages/main/Confirmation"
import NoMatch from "../pages/NoMatch"

import inputs from "../lists/inputs"

import validateInput from "../functions/validateInput.js"
import doFetchCaseAPI from "../functions/doFetchCaseAPI"
import changePage from "../functions/changePage"
import inputDiff from "../functions/inputDiff"

import useMerinfo from "../hooks/useMerinfo"
import useSNPAC from "../hooks/useSNPAC"

const Main = props => {

  const location = useLocation()
  const navigate = useNavigate()

  const getInitialInputState = () => {
    if (props.case.inputValues) {
      return caseInputState()
    }
    /*if (props.company.policy) {
      return policyInputState()
    }*/
    return defaultInputState()
  }

  const caseInputState = () => {
    return inputs.reduce((inputState, input) => {
      const value = props.case.inputValues[input.name] || defaultInputValue(input)
      const validated = value ? validateInput(input.name, value) : defaultInputValidated(input)
      inputState[input.name] = {value: value, validated: validated}
      return inputState
    }, {})
  }

  const policyInputState = () => {
    return inputs.reduce((inputState, input) => {
      const value = props.company.policy[input.name] || defaultInputValue(input)
      const validated = value ? validateInput(input.name, value) : defaultInputValidated(input)
      inputState[input.name] = {value: value, validated: validated}
      return inputState
    }, {})
  }

  const defaultInputState = () => {
    return inputs.reduce((inputState, input) => {
      const value = defaultInputValue(input)
      const validated = defaultInputValidated(input)
      inputState[input.name] = {value: value, validated: validated}
      return inputState
    }, {})
  }

  const defaultInputValue = (input) => {
    return input.validateAs == 'boolean' ? false : ''
  }

  const defaultInputValidated = (input) => {
    return input.validateAs == 'boolean' ? true : false
  }

  const [inputState, setInputState] = useState(getInitialInputState())
  const [caseID, setCaseID] = useState(props.case.id)
  //const [caseNumber, setCaseNumber] = useState(null)
  const [externalData, setExternalData] = useState({newOwner:{boards:{}}, currentOwner:{boards:{}}})
  const [signatoryNameListOptions, setSignatoryNameListOptions] = useState({newOwner:[], currentOwner:[]})
  const [doFetchExternalData, setDoFetchExternalData] = useState({operator:false, newOwner:false, currentOwner:false})
  const [doFetchVerifyOrgnr, setDoFetchVerifyOrgnr] = useState(false)
  const [condition, setCondition] = useState({isOperatorDiff:false, isDateDiff:false, isOrgnrDiff:false})
  const [pageTrail, setPageTrail] = useState(props.case.pageTrail || [])
  const [currentPath, setCurrentPath] = useState(null)
  const [orgnrVerified, setOrgnrVerified] = useState(false)
  const [orgnrVerifiedError, setOrgnrVerifiedError] = useState('')

  const fetchExternalData = {
    newOwner: useMerinfo(
      "getCompany",
      doFetchExternalData.newOwner,
      {orgnr:inputState.newOwnerOrgnr.value}
    ),
    currentOwner: useMerinfo(
      "getCompany",
      doFetchExternalData.currentOwner,
      {orgnr:inputState.currentOwnerOrgnr.value}
    ),
    operator: useSNPAC(
      "getOperator",
      doFetchExternalData.operator,
      {number:inputState.number.value}
    )
  }
  const fetchVerifyOrgnr = useMerinfo("getCompany", doFetchVerifyOrgnr, {orgnr:inputState.orgnr.value})

  const onExternalDataResponseRecieved = {
    operator: (response) => {
      if (!response.operator) return
      handleNewInputValue({name:"currentOperator", value:response.operator})
    },
    newOwner: (response) => {
      if (!response.company) return
      updateInputValuesWithData(response.company.basic, [
        {inputName:'newOwnerName', dataName:'name'},
        {inputName:'newOwnerAddressStreet', dataName:'address'},
        {inputName:'newOwnerAddressPostalCode', dataName:'zipcode'},
        {inputName:'newOwnerAddressCity', dataName:'city'}
      ])
      if (response.company.boards.length > 0) {
        addOptionsToSignatoryNameList('newOwner', response.company.boards)
        handleNewInputValue({name:'newOwnerSignatoryNameList', value:''})
        setExternalData(previousData  => ({...previousData, newOwner: {boards: response.company.boards}}))
      }
    },
    currentOwner: (response) => {
      if (!response.company) return
      updateInputValuesWithData(response.company.basic, [
        {inputName:'currentOwnerName', dataName:'name'},
        {inputName:'currentOwnerAddressStreet', dataName:'address'},
        {inputName:'currentOwnerAddressPostalCode', dataName:'zipcode'},
        {inputName:'currentOwnerAddressCity', dataName:'city'}
      ])
      if (response.company.boards.length > 0) {
        addOptionsToSignatoryNameList('currentOwner', response.company.boards)
        handleNewInputValue({name:'currentOwnerSignatoryNameList', value:''})
        setExternalData(previousData  => ({...previousData, currentOwner: {boards: response.company.boards}}))
      }
    }
  }

  const onExternalDataResponseError = {
    operator: (error) => {

    },
    newOwner: (error) => {
      setExternalData(previousData  => ({...previousData, newOwner: {boards: []}}))
      setSignatoryNameListOptions(previousData  => ({...previousData, newOwner: []}))
    },
    currentOwner: (error) => {
      setExternalData(previousData  => ({...previousData, currentOwner: {boards: []}}))
      setSignatoryNameListOptions(previousData  => ({...previousData, currentOwner: []}))
    }
  }

  const handleExternalDataResponse = (action) => {
    if (!fetchExternalData[action].response) return
    setDoFetchExternalData(previous  => ({...previous, [action]: false}))
    if (fetchExternalData[action].response.error) {
      onExternalDataResponseError[action](fetchExternalData[action].response.error)
      return
    }
    onExternalDataResponseRecieved[action](fetchExternalData[action].response)
  }

  const handleVerifyOrgnrResponse = () => {
    if (!fetchVerifyOrgnr.response) return
    setDoFetchVerifyOrgnr(false)
    if (fetchVerifyOrgnr.response.error) {
      setOrgnrVerified(false)
      setOrgnrVerifiedError('Ogiltigt organisationsnummer')
      return
    }
    setOrgnrVerified(true)
    setOrgnrVerifiedError('')
  }

  const updateInputValuesWithData = (data, fields) => {
    fields.map(field => {
      if (data[field.dataName]) {
        handleNewInputValue({name:field.inputName, value:data[field.dataName]})
      }
    })
  }

  useEffect(() => {
    switch(props.process.status) {
      /*case 'started':
        if (!caseID) {
          startNewCase()
        }
        break*/
      case 'ongoing':
      case 'awaiting_sign':
        changePage('confirmation', navigate)
        break
      default:
        break
    }
  }, [props.process.status])
 
  useEffect(() => {
    setCurrentPath(location.pathname.substring(location.pathname.lastIndexOf("/")+1))
  }, [location])

  useEffect(() => {
    if (inputState.number.validated) {
      setDoFetchExternalData(previous  => ({...previous, operator: true}))
    }
  }, [inputState.number.value, inputState.number.validated])

  useEffect(() => {
    if (inputState.newOwnerOrgnr.validated) {
      setDoFetchExternalData(previous  => ({...previous, newOwner: true}))
    }
  }, [inputState.newOwnerOrgnr.value, inputState.newOwnerOrgnr.validated])

  useEffect(() => {
    if (inputState.currentOwnerOrgnr.validated) {
      setDoFetchExternalData(previous  => ({...previous, currentOwner: true}))
    }
  }, [inputState.currentOwnerOrgnr.value, inputState.currentOwnerOrgnr.validated])

  useEffect(() => {
    if (validateInput('orgnr', inputState.orgnr.value)) {
      setDoFetchVerifyOrgnr(true)
      return
    }
    if (orgnrVerified) {
      setOrgnrVerified(false)
    }
  }, [inputState.orgnr.value])

  useEffect(() => {
    handleExternalDataResponse('operator')
  }, [fetchExternalData.operator.loading])

  useEffect(() => {
    handleExternalDataResponse('newOwner')
  }, [fetchExternalData.newOwner.loading])

  useEffect(() => {
    handleExternalDataResponse('currentOwner')
  }, [fetchExternalData.currentOwner.loading])

  useEffect(() => {
    handleVerifyOrgnrResponse()
  }, [fetchVerifyOrgnr.loading])

  useEffect(() => {
    if (!orgnrVerified) return
    handleNewInputValue({name:'orgnr', value:inputState.orgnr.value})
  }, [orgnrVerified])

  useEffect(() => {
    if (inputState.newOwnerSignatoryNameList.value) {
      const boardMember = externalData.newOwner.boards.find(member => {
        return member.name === inputState.newOwnerSignatoryNameList.value
      })
      if (boardMember && boardMember.personalNumber) {
        handleNewInputValue({name:'newOwnerSignatoryPersonalIdentityNumber', value:boardMember.personalNumber})
      }
    }
  }, [inputState.newOwnerSignatoryNameList.value])

  useEffect(() => {
    if (inputState.currentOwnerSignatoryNameList.value) {
      const boardMember = externalData.currentOwner.boards.find(member => {
        return member.name === inputState.currentOwnerSignatoryNameList.value
      })
      if (boardMember && boardMember.personalNumber) {
        handleNewInputValue({name:'currentOwnerSignatoryPersonalIdentityNumber', value:boardMember.personalNumber})
      }
    }
  }, [inputState.currentOwnerSignatoryNameList.value])

  useEffect(() => {
    if (inputState.orgnr.validated) {
      if (props.process.type == 'onboarding' && !inputState.newOwnerOrgnr.value) {
        handleNewInputValue({name:"newOwnerOrgnr", value:inputState.orgnr.value})
      }
      else if (props.process.type == 'offboarding' && !inputState.currentOwnerOrgnr.value) {
        handleNewInputValue({name:"currentOwnerOrgnr", value:inputState.orgnr.value})
      }
	  }
  }, [inputState.orgnr.value, inputState.orgnr.validated])

  /*useEffect(() => {
    const companyOrgnr = props.company.basic ? props.company.basic.orgnr : null
    if (companyOrgnr && !inputState.orgnr.validated) {
      handleNewInputValue({name:"orgnr", value:companyOrgnr})
    }
  }, [props.company])*/

  // conditions /////////////////////////////////
  useEffect(() => {
    const diff = inputDiff(inputState, ['newOperator', 'currentOperator'])
    if (condition.isOperatorDiff != diff) {
      setCondition(previousCondition => ({...previousCondition, isOperatorDiff: diff}))
    }
  }, [inputState.newOperator.value, inputState.currentOperator.value, inputState.newOperator.validated, inputState.currentOperator.validated])

  useEffect(() => {
    const diff = inputDiff(inputState, ['startDate', 'endDate'])
    if (condition.isDateDiff !== diff) {
      setCondition(previousCondition => ({...previousCondition, isDateDiff: diff}))
    }
  }, [inputState.startDate.value, inputState.endDate.value, inputState.startDate.validated, inputState.endDate.validated])

  useEffect(() => {
    const diff = inputDiff(inputState, ['currentOwnerOrgnr', 'newOwnerOrgnr'])
    if (condition.isOrgnrDiff !== diff) {
      setCondition(previousCondition => ({...previousCondition, isOrgnrDiff: diff}))
    }
  }, [inputState.currentOwnerOrgnr.value, inputState.newOwnerOrgnr.value, inputState.currentOwnerOrgnr.validated, inputState.newOwnerOrgnr.validated])
  ///////////////////////////////////////////////

  const addOptionsToSignatoryNameList = (list, boards) => {
    const options = boards.map((member) => {
      return {value:member.name, label:member.name}
    })
    options.unshift({value:'', label:'Välj firmatecknare'})
    setSignatoryNameListOptions(previousData  => ({
      ...previousData,
      [list]: options
    }))
  }

  const handlePageChange = newPage => {
    setCurrentPath(newPage)
  }

  const onInputBlur = async e => {
    console.log("blur", e.target.name)
    if (inputState[e.target.name].validated) {
      const result = doFetchCaseAPI("on-off-case", "save-input-value", {caseID:caseID, name:e.target.name, value:inputState[e.target.name].value})
    }
  }

  const startNewCase = async () => {
    console.log("startNewCase")
    try {
      const newCase = await doFetchCaseAPI("on-off-case", "start", {process:props.process.type, companyID:props.company.id || 0})
      console.log(newCase)
      if (!newCase.error) {
        //setCaseID(newCase.id)
        //setCaseNumber(newCase.caseNumber)
      }
    } catch(error) {
      // handle any error state, rejected promises, etc..
    }
  }

  const handleNewInputValue = async props => {
    if (caseID) {
      setInputState(prevState  => ({...prevState, [props.name]: { ...prevState[props.name], value:props.value }}))
      const validated = orgnrVerified ? validateInput(props.name, props.value) : false
      if (!validated) {
        setInputState(prevState  => ({...prevState, [props.name]: { ...prevState[props.name], validated:false }}))
        return
      }
      const result = await doFetchCaseAPI("on-off-case", "save-input-value", {caseID:caseID, name:props.name, value:props.value})
      if (!result.error) {
        setInputState(prevState  => ({...prevState, [props.name]: { ...prevState[props.name], validated:true }}))
      }
      //console.log("updating", props.name)
      //console.log("update", result)
      
      //console.log(props.name, props.value, validated);
      /*setInputState(previousInputState  => ({
        ...previousInputState,
        [props.name]: {
          value: props.value,
          validated: validated
        }
      }))*/
    }
  }

  const onInputChange = e => {
    const value = e.target.type == 'checkbox' ? e.target.checked : e.target.value.trim()
    handleNewInputValue({name:e.target.name, value:value})
  }

  /*const routeObject = {
    path: "/number-choice",
    routeElement: <NumberChoice
      inputState={inputState}
      onInputChange={onInputChange}
      onRadioChange={handleRadioChange}
    />
  }*/
  return (
    <div>
      <NavigationHandler
        caseID={caseID}
        process={props.process}
        inputState={inputState}
        condition={condition}
        setPageTrail={setPageTrail}
      />
      {/*<NavigationBar
        inputState={inputState}
        pages={testPages}
        lastPage={currentPage}
        onPageChange={handlePageChange}
  />*/}
      <Routes>
        <Route
          path="/start-onboarding"
          element={
            <StartOnboarding
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
              orgnrVerified={orgnrVerified}
              orgnrVerifiedError={orgnrVerifiedError}
              orgnrLoading={fetchVerifyOrgnr.loading}
            />
          }
        />
        <Route
          path="/start-offboarding"
          element={
            <StartOffboarding
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
              orgnrVerified={orgnrVerified}
              orgnrVerifiedError={orgnrVerifiedError}
              orgnrLoading={fetchVerifyOrgnr.loading}
            />
          }
        />
        <Route
          path="/number"
          element={
            <Number
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route
          path="/new-number"
          element={
            <NewNumber
              inputState={inputState}
              onInputChange={onInputChange}
              fetchOperatorLoading={fetchExternalData.operator.loading}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route 
          path="/replace-existing-user"
          element={
            <ReplaceExistingUser
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route 
          path="/change-data-plan"
          element={
            <ChangeDataPlan
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route
          path="/move-number"
          element={
            <MoveNumber
              inputState={inputState}
              onInputChange={onInputChange}
              handleNewInputValue={handleNewInputValue}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route
          path="/data-plan"
          element={
            <DataPlan
              inputState={inputState}
              onInputChange={onInputChange}
              handleNewInputValue={handleNewInputValue}
              fetchOperatorLoading={fetchExternalData.operator.loading}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route
          path="/sim-card"
          element={
            <SimCard
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route
          path="/has-subscription"
          element={
            <HasSubscription
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route
          path="/final-invoice"
          element={
            <FinalInvoice
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route
          path="/current-owner"
          element={
            <CurrentOwner
              inputState={inputState}
              onInputChange={onInputChange}
              handleNewInputValue={handleNewInputValue}
              fetchCurrentOwnerLoading={fetchExternalData.currentOwner.loading}
              signatoryNameListOptions={signatoryNameListOptions.currentOwner}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route
          path="/new-subscription"
          element={
            <NewSubscription
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route 
          path="/new-owner"
          element={
            <NewOwner
              inputState={inputState}
              onInputChange={onInputChange}
              handleNewInputValue={handleNewInputValue}
              fetchNewOwnerLoading={fetchExternalData.newOwner.loading}
              signatoryNameListOptions={signatoryNameListOptions.newOwner}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route 
          path="/case-manager"
          element={
            <CaseManager
              inputState={inputState}
              onInputChange={onInputChange}
              handleNewInputValue={handleNewInputValue}
              signatoryNameListOptions={signatoryNameListOptions}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route 
          path="/number-action"
          element={
            <NumberAction
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
              pageTrail={pageTrail}
              currentPath={currentPath}
            />
          }
        />
        <Route 
          path="/subscription-action"
          element={
            <SubscriptionAction
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
              pageTrail={pageTrail}
              currentPath={currentPath}
            />
          }
        />
        <Route
          path="/divert-number"
          element={
            <DivertNumber
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route
          path="/new-user"
          element={
            <NewUser
              inputState={inputState}
              onInputChange={onInputChange}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route 
          path="/summary"
          element={
            <Summary
              inputState={inputState}
              onInputChange={onInputChange}
              handleNewInputValue={handleNewInputValue}
              updateStatus={props.updateStatus}
              caseID={caseID}
              caseNumber={props.case.caseNumber}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route
          path="/confirmation"
          element={
            <Confirmation
              inputState={inputState}
              caseID={caseID}
              caseNumber={props.case.caseNumber}
              process={props.process}
              condition={condition}
            />
          }
        />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      {!['number-action', 'subscription-action', 'summary', 'confirmation'].includes(currentPath) && (
        <NavigationButtons
          inputState={inputState}
          condition={condition}
          process={props.process}
          currentPath={currentPath}
          pageTrail={pageTrail}
          disableForwardNavigation={!orgnrVerified}
        />
      )}
    </div>
  )
}
  
export default Main