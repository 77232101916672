import React, { useEffect } from "react"
import { useLocation, useNavigate } from 'react-router-dom';

import changePage from "../functions/changePage"
import getPageTrail from "../functions/getPageTrail"
import doFetchCaseAPI from "../functions/doFetchCaseAPI"

import config from "../config"

const NavigationHandler = props => {

  let location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const navigatedTo = sessionStorage.getItem(config.storageKey.navigatedTo)
    const pathName = location.pathname.substring(1)
    let redirectTo = ''
    if (!props.caseID && !props.process.status) {
      redirectTo = '/'
    }
    else if (pathName != navigatedTo && navigatedTo != 'previous') {
      //redirectTo = 'previous'
    }
    if (redirectTo) {
      changePage(redirectTo, navigate)
    }
    else if (props.caseID && pathName) {
      const pageTrail = getPageTrail("start-"+props.process.type, pathName, props.inputState, props.process, props.condition)
      props.setPageTrail(pageTrail)
      const result = doFetchCaseAPI("on-off-case", "save-current-page", {caseID:props.caseID, page:pathName})
      console.log("save last visited page", result)
      console.log("getPageTrail", pageTrail)
      
    }
  }, [location]);

  return (
    <div></div>
  )
}
    
export default NavigationHandler