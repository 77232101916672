import React, { useState, useEffect } from "react"

import Message from "./Message"

const CaseInfo = props => {
  return (
    <div>
      <Message text={'Ärendet startades '+props.info.created} />
      <Message text={'Status: '+props.info.status} />
    </div>
  )
}

export default CaseInfo