const getDocumentOptions = (inputData, document, caseNumber) => {
  const actionMessage = {
    portation: 'portering av nummer '+inputData.number,
    transfer: 'överlåtelse av nummer '+inputData.number+' från '+inputData.currentOwnerName+' till '+inputData.newOwnerName,
    mandate: 'fullmakt för hantering av nummer '+inputData.number,
    replace: 'nummerflytt av nummer '+inputData.number+' som ska ersätta nummer '+inputData.vacantNumber,
    portationTransfer: 'portering med överlåtelse av nummer '+inputData.number+' från '+inputData.currentOwnerName+' till '+inputData.newOwnerName
  }
  const documentOptions = {
    invitationMessage: 'Hej!\nDu är inbjuden till att signera dokument gällande en '+actionMessage[document.action]+'.\nÄrendenummer: '+caseNumber,
    confirmationMessage: 'Hej\nNu är dokumentet gällande '+actionMessage[document.action]+' signerat av alla parter.\nÄrendenummer: '+caseNumber,
    authenticationMethodToSign: 'se_bankid'
  }

  return documentOptions
}

export default getDocumentOptions