import React, { useState, useEffect } from "react"

import Button from "../../Button"
import Message from "../../Message"
import PlainTextInput from "../../TextInput"

import styles from "../../../css/Page.module.css"

import useManagerAPI from "../../../hooks/useManagerAPI"

import validateValue from "../../../functions/validateValue"

const NewAddress = props => {

  const onInputChange = e => {
    setData(previousData  => ({...previousData, [e.target.name]: e.target.value}))
  }

  const successfulResponse = (response) => {
    if (!response) return false
    if (response.error) {
      setResponseMessage({text:response.error, error:true})
      return false
    }
    return true
  }

  const validateData = () => {
    if (!validateValue(data.city, 'city')) {
      setResponseMessage({text:'Postort är ej korrekt ifylld', error:true})
      return false
    } 
    if (!validateValue(data.street, 'street')) {
      setResponseMessage({text:'Gatuadress är ej korrekt ifylld', error:true})
      return false
    }
    if (!validateValue(data.postalCode, 'postalCode')) {
      setResponseMessage({text:'Postnummer är ej korrekt ifylld', error:true})
      return false
    }
    return true
  }

  const saveAddress = () => {
    if (!validateData()) return
    setResponseMessage({text:'Sparar adress', error:false})
    setDoFetchSaveAddress(true)
  }

  const onFetchSaveAddressSuccess = () => {
    setDoFetchSaveAddress(false)
    if (fetchSaveAddress.response.addressID) {
      setData({})
      setResponseMessage({text:'', error:false}) // {text:'Adress sparad', error:false}
      props.insertCompanyAddress({id:fetchSaveAddress.response.addressID, ...data})
      props.setViewMode('list')
    }
  }

  const [data, setData] = useState({})
  const [doFetchSaveAddress, setDoFetchSaveAddress] = useState(false)
  const [responseMessage, setResponseMessage] = useState({text:'', error:false})

  const fetchSaveAddress = useManagerAPI('company', 'save-address', doFetchSaveAddress, {id:props.companyID, address:data}, props.accessToken)

  let classNames = []
  if (props.hide) {
    classNames.push(styles.hidden)
  }

  useEffect(() => {
    if (!successfulResponse(fetchSaveAddress.response)) return
    onFetchSaveAddressSuccess()
  }, [fetchSaveAddress.loading])
  
  return(
    <div className={classNames.join(" ")}>
      <div className={styles.inputContainer}>
        <PlainTextInput
          name='name'
          value={data.name || ''}
          onInputChange={onInputChange}
          placeholder='Kostnadsställe'
        />
        <PlainTextInput
          name='street'
          value={data.street || ''}
          onInputChange={onInputChange}
          placeholder='Gatuadress'
        />
        <div className={styles.textInputRow}>
          <PlainTextInput
            name='postalCode'
            value={data.postalCode || ''}
            onInputChange={onInputChange}
            placeholder='Postnummer'
          />
          <PlainTextInput
            name='city'
            value={data.city || ''}
            onInputChange={onInputChange}
            placeholder='Postort'
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          text="Spara adress"
          onClickFunction={saveAddress}
          disabled={fetchSaveAddress.loading}
        />
      </div>
      <Message
        text={responseMessage.text}
        error={responseMessage.error}
        loading={fetchSaveAddress.loading}
      />
    </div>
  )
}
    
export default NewAddress