import React, {useState, useEffect} from "react"

import Input from "./Input/Input"

import styles from "../css/Page.module.css"

const NewOwnerSignatory = props => {

  useEffect(() => {
    if (props.inputState.caseManagerIsSignatory.value == 'yes') {
      if (props.inputState.caseManagerName.validated) {
        props.handleNewInputValue({name:"newOwnerSignatoryName", value:props.inputState.caseManagerName.value})
      }
      if (props.inputState.caseManagerEmail.validated) {
        props.handleNewInputValue({name:"newOwnerSignatoryEmail", value:props.inputState.caseManagerEmail.value})
      }
      if (props.inputState.caseManagerPersonalIdentityNumber.validated) {
        props.handleNewInputValue({name:"newOwnerSignatoryPersonalIdentityNumber", value:props.inputState.caseManagerPersonalIdentityNumber.value})
      }
    }
    }, [
    props.inputState.caseManagerName.value,
    props.inputState.caseManagerEmail.value,
    props.inputState.caseManagerPersonalIdentityNumber.value,
    props.inputState.caseManagerName.validated,
    props.inputState.caseManagerEmail.validated,
    props.inputState.caseManagerPersonalIdentityNumber.validated,
    props.inputState.caseManagerIsSignatory.value
  ])

  useEffect(() => {
    if (props.inputState.newOwnerSignatoryNameList.value) {
      props.handleNewInputValue({name:"newOwnerSignatoryName", value:props.inputState.newOwnerSignatoryNameList.value})
    }
  }, [props.inputState.newOwnerSignatoryNameList.value])

  let classNames = [styles.inputContainer]

  if (props.hide) {
    classNames.push(styles.hidden)
  }

  return (
	<div className={classNames.join(" ")}>
	  <Input
		name="newOwnerSignatoryName"
		inputState={props.inputState}
		onInputChange={props.onInputChange}
		hide={props.signatoryNameListOptions.length > 0}
	  />
      <Input
        name="newOwnerSignatoryNameList"
        inputState={props.inputState}
        onInputChange={props.onInputChange}
        options={props.signatoryNameListOptions}
        hide={props.signatoryNameListOptions.length == 0}
      />
      <Input
        name="newOwnerSignatoryEmail"
        inputState={props.inputState}
        onInputChange={props.onInputChange}
      />
      <Input
        name="newOwnerSignatoryPersonalIdentityNumber"
        inputState={props.inputState}
        onInputChange={props.onInputChange}
      />
	</div>
  )
}

export default NewOwnerSignatory