import React, { useState, useEffect } from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"

import styles from "../../css/Page.module.css"

const MoveNumber = props => {

  useEffect(() => {
    const newMoveNumberChoiceValue = {'existing':'none','other':'number'}[props.inputState.numberChoice.value]
    if (newMoveNumberChoiceValue && !props.inputState.moveNumberChoice.value) {
      props.handleNewInputValue({name:"moveNumberChoice", value:newMoveNumberChoiceValue})
    }
  }, [props.inputState.numberChoice.value])

  return (
    <div>
      <Header title="Skall någon del flyttas?" />
      <div className={styles.inputContainer}>
        <Input
          name="moveNumberChoice"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          placeholder="Operatör"
          name="currentOperator"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(['both', 'subscription'].includes(props.inputState.moveNumberChoice.value))}
        />
      </div>
      {/*<NavigationButtons
        inputState={props.inputState}
  />*/}
    </div>
  )
}
  
export default MoveNumber