const documents = [
  {
    title: 'Portering av nummer - Telenor',
    name: 'portering-telenor',
    action: 'portation',
    operator: 'Telenor',
    inputs: [
      {name: 'caseSellerCompany', required: false},
      {name: 'caseSellerName', required: false},
      {name: 'caseSellerEmail', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'portationDate', required: false},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Överlåtelse av nummer - Telenor',
    name: 'overlatelse-telenor',
    action: 'transfer',
    operator: 'Telenor',
    inputs: [
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerAddressStreet', required: false},
      {name: 'currentOwnerAddressPostalCode', required: false},
      {name: 'currentOwnerAddressCity', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'number', required: false},
      {name: 'numberCount', required: false},
      {name: 'newOwnerName', required: false},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerAddressStreet', required: false},
      {name: 'newOwnerAddressPostalCode', required: false},
      {name: 'newOwnerAddressCity', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'newOwnerIsPrivate', required: false},
      {name: 'shallReplaceNumber', required: false},
      {name: 'documentViewers',  required: false}
    ]
  },
  {
    title: 'Portering av nummer med överlåtelse - Telenor',
    name: 'portering-overlatelse-telenor',
    action: 'portationTransfer',
    operator: 'Telenor',
    inputs: [
      {name: 'caseSellerCompany', required: false},
      {name: 'caseSellerName', required: false},
      {name: 'caseSellerEmail', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'portationDate', required: false},
      {name: 'newOwnerName', required: false},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Nummerflytt - Telenor',
    name: 'nummerflytt-telenor',
    action: 'replace',
    operator: 'Telenor',
    inputs: [
      {name: 'caseSellerCompany', required: false},
      {name: 'caseSellerName', required: false},
      {name: 'caseSellerEmail', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'portationDate', required: false},
      {name: 'newOwnerName', required: false},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Fullmakt - Telenor',
    name: 'fullmakt-telenor',
    action: 'mandate',
    operator: 'Telenor',
    inputs: [
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'mandateExpirationDate', required: false},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Portering av nummer - 3',
    name: 'portering-tre',
    action: 'portation',
    operator: '3',
    inputs: [
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Överlåtelse av nummer - 3',
    name: 'overlatelse-tre',
    action: 'transfer',
    operator: '3',
    inputs: [
      {name: 'currentOwnerIsPrivate', required: false},
      {name: 'currentOwnerIsCompany', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'number', required: false},
      {name: 'newOwnerIsPrivate', required: false},
      {name: 'newOwnerIsCompany', required: false},
      {name: 'newOwnerName', required: false},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Portering av nummer med överlåtelse - 3',
    name: 'portering-overlatelse-tre',
    action: 'portationTransfer',
    operator: '3',
    inputs: [
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'newOwnerName', required: false},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Nummerflytt - 3',
    name: 'nummerflytt-tre',
    action: 'replace',
    operator: '3',
    inputs: [
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'newOwnerName', required: false},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Uppsägning av nummer - Telia',
    name: 'uppsagning-telia',
    action: 'termination',
    operator: 'Telia',
    inputs: [
      {name: 'number', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerAddressStreet', required: false},
      {name: 'currentOwnerAddressPostalCodeCity', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Fullmakt vid byte av mobiloperatör - Telia',
    name: 'portering-telia',
    action: 'portation',
    operator: 'Telia',
    inputs: [
      {name: 'number', required: false},
      {name: 'vacantNumberText', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerAddressStreet', required: false},
      {name: 'currentOwnerAddressPostalCodeCity', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Fullmakt vid byte av mobiloperatör med överlåtelse - Telia',
    name: 'portering-overlatelse-telia',
    action: 'portationTransfer',
    operator: 'Telia',
    inputs: [
      {name: 'number', required: false},
      {name: 'vacantNumberText', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerAddressStreet', required: false},
      {name: 'currentOwnerAddressPostalCodeCity', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Nummerflytt - Telia',
    name: 'nummerflytt-telia',
    action: 'replace',
    operator: 'Telia',
    inputs: [
      {name: 'number', required: false},
      {name: 'vacantNumberText', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerAddressStreet', required: false},
      {name: 'currentOwnerAddressPostalCodeCity', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Överlåtelse av nummer - Telia',
    name: 'overlatelse-telia',
    action: 'transfer',
    operator: 'Telia',
    inputs: [
      {name: 'number', required: false},
      {name: 'isMobile', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerAddressStreet', required: false},
      {name: 'currentOwnerAddressPostalCodeCity', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'transferDate', required: false},
      {name: 'newOwnerName', required: false},
      {name: 'newOwnerAddressStreet', required: false},
      {name: 'newOwnerAddressPostalCodeCity', required: false},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Portering av nummer - Telavox',
    name: 'portering-telavox',
    action: 'portation',
    operator: 'Telavox',
    inputs: [
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'acceptMandate', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerAddressComplete', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Portering av nummer med överlåtelse - Telavox',
    name: 'portering-overlatelse-telavox',
    action: 'portationTransfer',
    operator: 'Telavox',
    inputs: [
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'acceptMandate', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerAddressComplete', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'newOwnerName', required: false},
      {name: 'newOwnerAddressComplete', required: false},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Nummerflytt - Telavox',
    name: 'nummerflytt-telavox',
    action: 'replace',
    operator: 'Telavox',
    inputs: [
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'acceptMandate', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerAddressComplete', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'newOwnerName', required: false},
      {name: 'newOwnerAddressComplete', required: false},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Överlåtelse av nummer - Telavox',
    name: 'overlatelse-telavox',
    action: 'transfer',
    operator: 'Telavox',
    inputs: [
      {name: 'number', required: false},
      {name: 'transferDate', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerAddressComplete', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'newOwnerName', required: false},
      {name: 'newOwnerAddressComplete', required: false},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Överlåtelse - Tele2',
    name: 'overlatelse-tele2',
    action: 'transfer',
    operator: 'Tele2',
    inputs: [
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'sendSimCard', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'newOwnerName', required: false},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Portering av nummer - Tele2',
    name: 'portering-tele2',
    action: 'portation',
    operator: 'Tele2',
    inputs: [
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'currentOperator', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerAddressStreet', required: false},
      {name: 'currentOwnerAddressPostalCodeCity', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Portering av nummer med överlåtelse - Tele2',
    name: 'portering-overlatelse-tele2',
    action: 'portationTransfer',
    operator: 'Tele2',
    inputs: [
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'currentOperator', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerAddressStreet', required: false},
      {name: 'currentOwnerAddressPostalCodeCity', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  },
  {
    title: 'Nummerflytt - Tele2',
    name: 'nummerflytt-tele2',
    action: 'replace',
    operator: 'Tele2',
    inputs: [
      {name: 'number', required: false},
      {name: 'vacantNumber', required: false},
      {name: 'currentOperator', required: false},
      {name: 'currentOwnerName', required: false},
      {name: 'currentOwnerOrgnr', required: false},
      {name: 'currentOwnerAddressStreet', required: false},
      {name: 'currentOwnerAddressPostalCodeCity', required: false},
      {name: 'currentOwnerSignatoryName', required: false},
      {name: 'currentOwnerSignatoryEmail', required: true},
      {name: 'currentOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'newOwnerOrgnr', required: false},
      {name: 'newOwnerSignatoryName', required: false},
      {name: 'newOwnerSignatoryEmail', required: true},
      {name: 'newOwnerSignatoryPersonalIdentityNumber', required: true},
      {name: 'documentViewers', required: false}
    ]
  }
]

const listFunctions = {
  getDocument: (action, operator) => {
    const document = documents.find(document => {
      return (document.action === action && document.operator === operator)
    })
    return document
  }
}

export default documents
export { listFunctions }