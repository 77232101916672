import getCompanyType from "./getCompanyType"

const getCaseSpecificDocumentAction = (process, action, validatedInputData) => {
  if (
    process.type == 'offboarding'
    && action == 'transfer'
    && validatedInputData.currentOperator == 'Telavox'
  ) {
    return 'portation'
  }
  if (
    process.type == 'offboarding'
    && action == 'transfer'
    && validatedInputData.currentOperator == 'Telenor'
    && getCompanyType(validatedInputData.newOwnerOrgnr) == 'private'
  ) {
    return 'mandate'
  }
  /*if (['Telia', '3', 'Tele2'].includes(validatedInputData.newOperator) && action == 'portation') {
    return 'inportation'
  }*/
  return ''
}

export default getCaseSpecificDocumentAction