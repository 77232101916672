import React from "react"
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from "react-router-dom"

import AppContainer from "./components/AppContainer"

import "./css/App.css"

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  //<React.StrictMode>
    <Router>
      <AppContainer />
    </Router>
  //</React.StrictMode>
);