import React from "react"

import Tooltip from "../../../Tooltip"

import styles from "../../../../css/TextInput.module.css"

const Text = props => {
  let classNames = []
  if (props.inputState[props.name].validated) {
    classNames.push(styles.validated)
  }
  if (props.loading) {
    classNames.push(styles.loading)
  }
  if (props.error && !props.inputState[props.name].validated) {
    classNames.push(styles.error)
  }
  return (
    <div className={styles.input}>
      <input
        type="text"
        name={props.name}
        value={props.inputState[props.name].value}
        onChange={props.onInputChange}
        placeholder={props.placeholder}
        className={classNames.join(" ")}
      />
      {props.tooltip && (
        <div className={styles.tooltip}>
          <Tooltip text={props.tooltip} />
        </div>
      )}
    </div>
  )
}

export default Text