import React, { useState, useEffect } from "react"

import Button from "./Button"
import Message from "./Message"
import Input from "./Input/Input"

import useAPI from "../hooks/useAPI"
import useCaseAPI from "../hooks/useCaseAPI"
import useReCaptcha from "../hooks/useReCaptcha"

import doFetchCaseAPI from "../functions/doFetchCaseAPI"
import getValidatedInputData from "../functions/getValidatedInputData"
import getScriveDocuments from "../functions/getScriveDocuments"

import config from "../config"

import styles from "../css/Page.module.css"

const DocumentSigning = props => {
  
  const verifyCaptcha = () => {
    window.grecaptcha.enterprise.execute(config.reCaptcha.key, {action: 'sign'}).then(function(token) {
      setFetchResponseMessage('Verifierar reCAPTCHA')
      setReCaptchaToken(token)
      setDoFetchReCaptcha(true)
    })
  }

  const startSigningProcess = () => {
    const validatedInputData = getValidatedInputData(props.inputState)
    const documents = getScriveDocuments(props.documents, validatedInputData, props.process, props.caseNumber)
    if (documents.length > 0) {
      setFetchResponseMessage('Skickar dokument för signering')
      setScriveDocuments(documents)
      setDoFetchScrive(true)
    }
  }

  const handleFetchResponse = (response, action) => {
    if (response) {
      onFetchResponseRecieved[action](response)
    }
  }

  const onFetchResponseRecieved = {
    scrive: (response) => {
      setFetchResponseMessage(response.error || '') // 'Alla dokument skickade för signering'
      setDoFetchScrive(false)

      if (!response.error) {
        //const result = doFetchCaseAPI("on-off-case", "save-documents", {caseID:props.caseID, documentIDs:scrive.response.documentIDs})
        //props.completeCase(true)
        setDocumentIDs(scrive.response.documentIDs)
      }
    },
    onOffCase: (response) => {
      setFetchResponseMessage(response.error || '')
      setDoFetchOnOffCase(false)

      if (!response.error) {
        props.completeCase(true)
      }
    },
    reCaptcha: (response) => {
      //console.log(response)
      setFetchResponseMessage(response.error || '')
      setDoFetchReCaptcha(false)

      if (!response.error) {
        if (reCaptcha.response.verification.score <= 0.2) {
          handleHighRiskReCaptcha()
        }
        else {
          startSigningProcess()
        }
      }
    }
  }

  const handleHighRiskReCaptcha = () => {
    if (window.confirm('Bekräfta att du inte är en robot')) {
      startSigningProcess()
    }
  }

  const [doFetchScrive, setDoFetchScrive] = useState(false)
  const [doFetchOnOffCase, setDoFetchOnOffCase] = useState(false)
  const [doFetchReCaptcha, setDoFetchReCaptcha] = useState(false)
  const [fetchResponseMessage, setFetchResponseMessage] = useState('')
  const [scriveDocuments, setScriveDocuments] = useState([])
  const [documentIDs, setDocumentIDs] = useState([])
  const [reCaptchaToken, setReCaptchaToken] = useState('')

  const scrive = useAPI("scrive", "sign-documents", doFetchScrive, {documents:scriveDocuments})
  const onOffCase = useCaseAPI("on-off-case", "save-documents", doFetchOnOffCase, {caseID:props.caseID, documentIDs:documentIDs})
  const reCaptcha = useReCaptcha(doFetchReCaptcha, {token:reCaptchaToken})

  let classNames = [styles.inputContainer]

  if (props.hide) {
    classNames.push(styles.hidden)
  }

  useEffect(() => {
    if (documentIDs.length > 0) {
      setDoFetchOnOffCase(true)
    }
  }, [documentIDs])

  useEffect(() => {
    handleFetchResponse(scrive.response, 'scrive')
  }, [scrive.loading])

  useEffect(() => {
    handleFetchResponse(onOffCase.response, 'onOffCase')
  }, [onOffCase.loading])

  useEffect(() => {
    handleFetchResponse(reCaptcha.response, 'reCaptcha')
  }, [reCaptcha.loading])

  return (
    <div className={classNames.join(" ")}>
      <div>
        <Message text='Klicka på knappen för att bjuda in till signering' />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          text='Skicka dokument för e-signering'
          onClickFunction={verifyCaptcha}
          loading={scrive.loading || onOffCase.loading || reCaptcha.loading}
          disabled={scrive.loading || onOffCase.loading || reCaptcha.loading || props.inputState.isGeneralAgreementAccepted.value === false}
        />
      </div>
      <div>
        <Input
          name="isGeneralAgreementAccepted"
          label={<span>Jag accepterar de <a href={config.documentURL.termsAndConditions} target='_blank'>allmänna villkoren</a></span>}
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
      </div>
      <div>
        <Message
          text={fetchResponseMessage}
          //loading={scrive.loading || onOffCase.loading || reCaptcha.loading}
          error={
            (scrive.response && scrive.response.error) ||
            (onOffCase.response && onOffCase.response.error) ||
            (reCaptcha.response && reCaptcha.response.error)
          }
        />
      </div>
    </div>
  )
}

export default DocumentSigning