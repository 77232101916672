import { listFunctions as pageListFunctions } from "../lists/pages";

const getPageTrail = (startPath, endPath, inputState, process, condition) => {
  if (startPath === endPath) {
    return []
  }
  return iterateNextPage([], startPath, endPath, inputState, process, condition)
}

const iterateNextPage = (trail, currentPath, endPath, inputState, process, condition) => {
  const page = pageListFunctions.getPage(currentPath)
  if (!page) {
    return []
  }
  trail.push(currentPath)
  const nextPath = page.getNextPath(inputState, process, condition)
  if (nextPath && nextPath !== endPath) {
    iterateNextPage(trail, nextPath, endPath, inputState, process, condition)
  }
  return trail
}

export default getPageTrail