import React from "react"

import { default as inputStyles } from "../css/Input.module.css"
import { default as selectStyles } from "../css/Select.module.css"

const Select = props => {

  const title = props.title ? <div className={inputStyles.title}>{props.title}</div> : ''

  return (
    <div>
      <div>
        {title}
      </div>
      <div className={selectStyles.input}>
        <select
          name={props.name}
          value={props.value}
          onChange={props.onInputChange}
        >
          {props.options.map((option, index) => (
            <option key={index} value={option.value} disabled={!option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default Select