import React from "react"

import Header from "../components/Header"

const NoMatch = () => {
    return (
        <div>
            <Header title="404 Page Not Found" />
        </div>
    )
}

export default NoMatch