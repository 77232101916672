const detectPoweredByOverlap = () => {
    let mainAppRect = document.getElementById("main-app-container").getBoundingClientRect();
    let poweredByRect = document.getElementById("powered-by-buddy").getBoundingClientRect();

    if (mainAppRect.left <= poweredByRect.right) {  // && mainAppRect.bottom >= poweredByRect.top
      return true
    }
    return false
}

export default detectPoweredByOverlap