import React, { useState, useEffect } from "react"

import Button from "../../Button"
import Message from "../../Message"
import TableList from "../../TableList"

import styles from "../../../css/Page.module.css"

import useCaseAPI from "../../../hooks/useCaseAPI"

const Case = props => {

  const resumeCase = () => {
    setDoFetchResumeCase(true)
  }

  const successfulResponse = (response) => {
    if (!response) return false
    setDoFetchResumeCase(false)
    if (response.error) {
      setResponseMessage({text:response.error, error:true})
      return false
    }
    return true
  }

  const onFetchResumeCaseSuccess = () => {
    props.initializeCase(
      {
        id:props.data.id,
        caseNumber:props.data.caseNumber,
        process: props.data.process,
        status: props.data.status,
        inputValues:fetchResumeCase.response.inputValues
      },
      props.data.currentPage // '/start-'+props.data.process
    )
  }

  const [doFetchResumeCase, setDoFetchResumeCase] = useState(false)
  const [responseMessage, setResponseMessage] = useState({text:'', error:false})

  const fetchResumeCase = useCaseAPI('on-off-case', 'resume', doFetchResumeCase, {caseID:props.data.id}, props.accessToken)

  useEffect(() => {
    if (!successfulResponse(fetchResumeCase.response)) return
    onFetchResumeCaseSuccess()
  }, [fetchResumeCase.loading])

  return (
    <div className={styles.itemContainer}>
      <div>
        <TableList
          items={[
            {title: 'Ärendenummer', text: props.data.caseNumber},
            {title: 'Status', text: props.data.statusText},
            {title: 'Startat', text: props.data.created}
          ]}
        />
      </div>
      {props.data.status === 'started' && (
        <div className={styles.buttonContainer}>
          <Button
            text='Återuppta ärende'
            onClickFunction={resumeCase}
            disabled={fetchResumeCase.loading}
            loading={fetchResumeCase.loading}
          />
        </div>
      )}
    </div>
  )
}

export default Case