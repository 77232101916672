import React, {useState, useEffect} from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"
import CurrentOwnerSignatory from "../../components/CurrentOwnerSignatory"

import getCompanyType from "../../functions/getCompanyType"

import styles from "../../css/Page.module.css"

const CurrentOwner = props => {

  const [companyType, setCompanyType] = useState('')

  useEffect(() => {
    if (props.inputState.currentOwnerOrgnr.validated) {
      setCompanyType(getCompanyType(props.inputState.currentOwnerOrgnr.value))
    }
  }, [props.inputState.currentOwnerOrgnr.value, props.inputState.currentOwnerOrgnr.validated])

  useEffect(() => {
    if (companyType === 'private') {
      props.handleNewInputValue({name:"currentOwnerSignatoryPersonalIdentityNumber", value:props.inputState.currentOwnerOrgnr.value})
    }
  }, [companyType])

  /*useEffect(() => {
    if (props.inputState.currentOwnerSignatoryNameList.validated) {
      props.handleNewInputValue({name:"currentOwnerSignatoryName", value:props.inputState.currentOwnerSignatoryNameList.value})
    }
  }, [props.inputState.currentOwnerSignatoryNameList.value])*/
  
  return (
    <div>
      <Header title="Vem äger numret idag?" />
      <div className={styles.inputContainer}>
        <Input
          name="currentOwnerOrgnr"
          placeholder={(props.process.type == 'onboarding' ? 'Person-/organisationsnummer' : null)}
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="currentOwnerName"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          loading={props.fetchCurrentOwnerLoading}
        />
        <Input
          name="currentOwnerAddressStreet"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          loading={props.fetchCurrentOwnerLoading}
        />
        <div className={styles.textInputRow}>
          <Input
            name="currentOwnerAddressPostalCode"
            inputState={props.inputState}
            onInputChange={props.onInputChange}
            loading={props.fetchCurrentOwnerLoading}
          />
          <Input
            name="currentOwnerAddressCity"
            inputState={props.inputState}
            onInputChange={props.onInputChange}
            loading={props.fetchCurrentOwnerLoading}
          />
        </div>
        {props.process.type == 'onboarding' && (
          <CurrentOwnerSignatory
            inputState={props.inputState}
            onInputChange={props.onInputChange}
            signatoryNameListOptions={props.signatoryNameListOptions}
            handleNewInputValue={props.handleNewInputValue}
            hide={(companyType === 'private')}
          />
        )}
        {/*<Input
          name="currentOwnerSignatoryName"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={props.process.type == 'offboarding' || props.signatoryNameListOptions.length > 0 || isPrivate}
        />
        <Input
          name="currentOwnerSignatoryNameList"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          options={props.signatoryNameListOptions}
          hide={props.process.type == 'offboarding' || props.signatoryNameListOptions.length == 0 || isPrivate}
        />
        <Input
          name="currentOwnerSignatoryEmail"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={props.process.type == 'offboarding' || isPrivate}
        />
        <Input
          name="currentOwnerSignatoryPersonalIdentityNumber"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={props.process.type == 'offboarding' || isPrivate}
  />*/}
      </div>
      {/*<NavigationButtons
        inputState={props.inputState}
        process={props.process}
      />*/}
    </div>
  )
}
  
export default CurrentOwner