import React from "react"

import { default as inputStyles } from "../css/Input.module.css"
import { default as textInputStyles } from "../css/TextInput.module.css"

const TextInput = props => {

  const title = props.title ? <div className={inputStyles.title}>{props.title}</div> : ''

  let classNames = []
  if (props.hide) {
    classNames.push(inputStyles.hidden)
  }

  return (
    <div className={classNames.join(" ")}>
      <div>
        {title}
      </div>
      <div className={textInputStyles.input}>
        <input
          type="text"
          name={props.name}
          value={props.value}
          onChange={props.onInputChange}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  )
}

export default TextInput