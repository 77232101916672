import React, { useState } from "react"

import styles from "../css/Tooltip.module.css"

const Tooltip = props => {

  const [visible, setVisible] = useState(false)

  return (
    <div className={styles.container}>
      <div onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
        ?{ /* change to icon */}
      </div>
      <div className={visible ? styles.visible : styles.hidden}>
        <div className={styles.textContainer}>
          {props.text}
        </div>
      </div>
    </div>
  )
}

export default Tooltip