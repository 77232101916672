import React from "react";

import { default as inputStyles } from "../css/Input.module.css"
import { default as radioInputStyles } from "../css/RadioInput.module.css"

const RadioInputGroup = props => {

  const title = props.title ? <div className={inputStyles.title}>{props.title}</div> : ''

  return (
    <div>
      <div>
        {title}
      </div>
      {props.options.map((option, index) => (
        <div className={radioInputStyles.container} key={index}>
          <div className={radioInputStyles.input}>
            <input
              type="radio"
              name={props.name}
              value={option.value}
              checked={props.value == option.value}
              onChange={props.onInputChange}
            />
          </div>
          <div className={radioInputStyles.label}>
            {option.label}
          </div>
        </div>
      ))}
    </div>
  )
}

export default RadioInputGroup