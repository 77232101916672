const pages = [
  {
    path: 'start-onboarding',
    getNextPath: (inputState, process) => {
      return 'number'
    }
  },
  {
    path: 'start-offboarding',
    getNextPath: (inputState, process) => {
      return 'number-action'
    }
  },
  {
    path: 'number',
    getNextPath: (inputState, process) => {
      if (inputState.numberChoice.value == 'other') {
        return 'new-subscription' // current-owner
      }
      if (inputState.numberChoice.value == 'existing') {
        return 'move-number'
      }
      return 'new-number'
    }
  },
  {
    path: 'new-number',
    getNextPath: (inputState, process) => {
      return 'data-plan'
    }
  },
  {
    path: 'data-plan',
    getNextPath: (inputState, process) => {
      return 'sim-card'
    }
  },
  {
    path: 'sim-card',
    getNextPath: (inputState, process, condition) => {
      if (condition.isOperatorDiff && inputState.numberChoice.value == 'other') {
        return 'final-invoice'
      }
      if (process.type == 'offboarding' || inputState.numberChoice.value == 'other') {
        return 'current-owner'
      }
      return 'new-owner'
    }
  },
  {
    path: 'has-subscription',
    getNextPath: (inputState, process) => {
      if (inputState.hasSubscriptionChoice.value == 'no') {
        return 'current-owner'
      }
      return 'final-invoice'
    }
  },
  {
    path: 'final-invoice',
    getNextPath: (inputState, process) => {
      return 'current-owner'
    }
  },
  {
    path: 'current-owner',
    getNextPath: (inputState, process) => {
      if (process.type == 'offboarding') {
        return 'case-manager'
      }
      return 'new-owner'
    }
  },
  {
    path: 'move-number',
    getNextPath: (inputState, process) => {
      if (inputState.moveNumberChoice.value == 'number') {
        return 'new-subscription'
      }
      return 'data-plan'
    }
  },
  {
    path: 'new-subscription',
    getNextPath: (inputState, process) => {
      return 'data-plan'
    }
  },
  {
    path: 'replace-existing-user',
	  getNextPath: (inputState, process) => {
      if (inputState.replaceExistingUserChoice.value == 'no') {
        return 'change-data-plan'
      }
	    return 'summary'
	  }
  },
  {
    path: 'change-data-plan',
    getNextPath: (inputState, process) => {
      return 'new-owner'
    }
  },
  {
    path: 'new-owner',
    getNextPath: (inputState, process) => {
      if (process.type == 'offboarding') {
        return 'current-owner'
      }
      return 'case-manager'
    }
  },
  {
    path: 'case-manager',
    getNextPath: (inputState, process) => {
      return 'summary'
    }
  },
  {
    path: 'number-action',
    getNextPath: (inputState, process) => {
      if (inputState.numberActionChoice.value == 'user') {
        return 'new-user'
      }
      if (inputState.numberActionChoice.value == 'transfer') {
        return 'subscription-action'
      }
      if (inputState.numberActionChoice.value == 'save') {
        return 'divert-number'
      }
      return 'summary'
    }
  },
  {
    path: 'subscription-action',
    getNextPath: (inputState, process) => {
      return 'new-owner'
    }
  },
  {
    path: 'new-user',
    getNextPath: (inputState, process, condition) => {
      if (condition.isDateDiff) {
        return 'divert-number'
      }
      return 'data-plan'
    }
  },
  {
    path: 'divert-number',
    getNextPath: (inputState, process) => {
      if (inputState.numberActionChoice.value == 'user') {
        return 'data-plan'
      }
      return 'summary'
    }
  },
  {
    path: 'summary',
    getNextPath: (inputState, process) => {
      return ''
    }
  }
]

const listFunctions = {
  getPage: (path) => {
    const page = pages.find(page => {
      return page.path === path
    })
    return page
  }
}

export default pages
export { listFunctions }