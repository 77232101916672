import React, { useState, useEffect } from "react"

import Title from "../Title"
import Button from "../Button"
import Message from "../Message"
import TextInput from "../TextInput"
import MerinfoCompanyInfo from "./MerinfoCompanyInfo"

import useManagerAPI from "../../hooks/useManagerAPI"

import validateValue from "../../functions/validateValue"

import styles from "../../css/Page.module.css"

const NewCompany = props => {

  const onInputChange = e => {
    setData(previousData  => ({...previousData, [e.target.name]: e.target.value}))
  }

  const successfulResponse = (response) => {
    if (!response) return false
    if (response.error) {
      setResponseMessage({text:response.error, error:true})
      return false
    }
    return true
  }

  const validateData = () => {
    if (!validateValue(data.orgnr, 'orgnr')) {
      setResponseMessage({text:'Organisationsnummer är ej korrekt ifylld', error:true})
      return false
    } 
    return true
  }

  const saveCompany = () => {
    if (!validateData()) return
    setResponseMessage({text:'Sparar företag', error:false})
    setDoFetchSaveCompany(true)
  }

  const onFetchSaveCompanySuccess = () => {
    props.setCompanyID(fetchSaveCompany.response.companyID)
  }

  const [data, setData] = useState({})
  const [companyInfo, setCompanyInfo] = useState({})
  const [doFetchSaveCompany, setDoFetchSaveCompany] = useState(false)
  const [responseMessage, setResponseMessage] = useState({})

  const fetchSaveCompany = useManagerAPI('manager', 'save-company', doFetchSaveCompany, {company:companyInfo}, props.accessToken)

  useEffect(() => {
    if (!successfulResponse(fetchSaveCompany.response)) return
    onFetchSaveCompanySuccess()
  }, [fetchSaveCompany.loading])

  return (
    <div>
      <Title text='Lägg till nytt företag' />
      <div className={styles.inputContainer}>
        <TextInput
          name='orgnr'
          value={data.orgnr || ''}
          onInputChange={onInputChange}
          placeholder='Organisationsnummer'
        />
      </div>
      <div className={styles.itemContainer}>
        <MerinfoCompanyInfo
          orgnr={data.orgnr}
          setCompanyInfo={setCompanyInfo}
        />
        <div className={styles.buttonContainer}>
          <Button
            text='Lägg till nytt företag'
            onClickFunction={saveCompany}
            disabled={Object.keys(companyInfo).length === 0}  
          /> 
        </div>
      </div>
      <Message
        text={responseMessage.text}
        error={responseMessage.error}
        loading={fetchSaveCompany.loading}
      />
    </div>
  )
}

export default NewCompany