import React from "react"

import styles from "../../../../css/Select.module.css"

const Select = props => {
  return (
    <div className={styles.input}>
      <select
        name={props.name}
        value={props.inputState[props.name].value}
        onChange={props.onInputChange}
      >
        {props.options.map((option, index) => (
          <option key={index} value={option.value} disabled={!option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  )
}

export default Select