import React, { useState, useEffect } from "react"

import Header from "../components/Header"
import Message from "../components/Message"
import Button from "../components/Button"

import ManagerTab from "../components/company-profile/manager/ManagerTab"
import AddressTab from "../components/company-profile/address/AddressTab"
import PolicyTab from "../components/company-profile/policy/PolicyTab"
import CaseTab from "../components/company-profile/case/CaseTab"

import styles from "../css/CompanyProfile.module.css"

const CompanyProfile = props => {

  /*const updateResponseMessage = props => {
    const text = props.text || ''
    const error = props.error || false
    const loading = props.loading || false
    setResponseMessage({text:text, error:error, loading:loading})
  }*/

  /*const successfulResponse = (response) => {
    if (!response) return false
    if (response.error) {
      updateResponseMessage({text:response.error, error:true})
      return false
    }
    return true
  }*/

  const test = () => {
    console.log('company', props.company)
  }

  const [activeTab, setActiveTab] = useState('manager')
  //const [responseMessage, setResponseMessage] = useState({})

  /*useEffect(() => {
    console.log(props.company.caseList)
  }, [])*/

  return(
    <div>
      <Header title="Företagsprofil" />
      <div className={styles.tabMenu}>
        <ul>
          <li
            className={activeTab === 'manager' ? styles.tabActive : ''}
            onClick={() => setActiveTab('manager')}
          >
            ADMINISTRATÖRER
          </li>
          <li
            className={activeTab === 'case' ? styles.tabActive : ''}
            onClick={() => setActiveTab('case')}
          >
            ÄRENDEN
          </li>
          <li
            className={activeTab === 'address' ? styles.tabActive : ''}
            onClick={() => setActiveTab('address')}
          >
            ADRESSER
          </li>
          <li
            className={activeTab === 'policy' ? styles.tabActive : ''}
            onClick={() => setActiveTab('policy')}
          >
            POLICY
          </li>
        </ul>
      </div>
      <div className={styles.tabContainer}>
        <ManagerTab
          companyID={props.company.id}
          setCompany={props.setCompany}
          managerList={props.company.managerList || []}
          accessToken={props.accessToken}
          hide={activeTab !== 'manager'}
        />
        <AddressTab
          companyID={props.company.id}
          setCompany={props.setCompany}
          //updateResponseMessage={updateResponseMessage}
          //successfulResponse={successfulResponse}
          addressList={props.company.addressList || []}
          accessToken={props.accessToken}
          hide={activeTab !== 'address'}
        />
        <PolicyTab
          companyID={props.company.id}
          setCompany={props.setCompany}
          //updateResponseMessage={updateResponseMessage}
          //successfulResponse={successfulResponse}
          policy={props.company.policy || {}}
          accessToken={props.accessToken}
          hide={activeTab !== 'policy'}
        />
        <CaseTab
          caseList={props.company.caseList || []}
          initializeCase={props.initializeCase}
          setProcess={props.setProcess}
          accessToken={props.accessToken}
          hide={activeTab !== 'case'}
        />
      </div>
      <div>
        {/*<Message
          text={responseMessage.text}
          error={responseMessage.error}
          loading={responseMessage.loading}
  />*/}
        {/*<Button text="test" onClickFunction={test} />*/}
      </div>
    </div>
  )
}

export default CompanyProfile