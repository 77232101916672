import React, { useState, useEffect } from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"
import Message from "../../components/Message"

import styles from "../../css/Page.module.css"

import config from "../../config"

const SubscriptionAction = props => {

  const [forceAcceptCloseNumber, setForceAcceptCloseNumber] = useState(false)

  useEffect(() => {
    setForceAcceptCloseNumber(props.inputState.subscriptionActionChoice.value === 'close')
  }, [props.inputState.subscriptionActionChoice.value])

  return (
    <div>
      <Header title="Vad ska hända med abonnemanget?" />
      <div className={styles.inputContainer}>
        <Input
          name="subscriptionActionChoice"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Message
          text={config.message.closeNumberCost}
          hide={!(forceAcceptCloseNumber)}
        />
        <Input
          name="isCloseNumberAccepted"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(forceAcceptCloseNumber)}
        />
      </div>
      <NavigationButtons
        inputState={props.inputState}
        process={props.process}
        condition={props.condition}
        pageTrail={props.pageTrail}
        currentPath={props.currentPath}
        disableForwardNavigation={(
          forceAcceptCloseNumber
          && props.inputState.isCloseNumberAccepted.value === false
        )}
      />
    </div>
  )
}

export default SubscriptionAction