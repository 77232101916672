import React from "react"

import logo from "../images/buddy-logo.png"
import logoDune from "../images/buddy-logo-dune.png"

import styles from "../css/PoweredBy.module.css"

const PoweredBy = props => {

  return (
    <div id="powered-by-buddy" className={props.overlap ? styles.wrapper : styles.fixedWrapper}>
      <div className={styles.content}>
        <div>Powered by</div>
        <div>
          <a href="https://www.buddy.se" target="_blank">
            <img src={props.overlap ? logo : logoDune} height="25px" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default PoweredBy