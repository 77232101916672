import React from "react"

import styles from "../../../../css/RadioInput.module.css"

const Radio = props => {
  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <input
          type="radio"
          name={props.name}
          value={props.value}
          checked={props.inputState[props.name].value === props.value}
          onChange={props.onInputChange}
        />
      </div>
      <div className={styles.label}>
        {props.label}
      </div>
    </div>
  )
}

export default Radio