import React, { useState, useEffect } from "react"

import Header from "../../components/Header"
import Message from "../../components/Message"
import DocumentHandling from "../../components/DocumentHandling"
import Button from "../../components/Button"

import styles from "../../css/Summary.module.css"
import pageStyles from "../../css/Page.module.css"

import doFetchCaseAPI from "../../functions/doFetchCaseAPI"
import getValidatedInputData from "../../functions/getValidatedInputData"
import getCaseActions from "../../functions/getCaseActions"
import getCaseDocuments from "../../functions/getCaseDocuments"
import getCaseSummary from "../../functions/getCaseSummary"

const Summary = props => {

  const validatedInputData = getValidatedInputData(props.inputState)
  const actions = getCaseActions(validatedInputData, props.condition)
  const documents = getCaseDocuments(props.process, actions, validatedInputData)
  const summary = getCaseSummary(actions, validatedInputData)

  const createDocuments = (
    documents.length > 0
    && validatedInputData.currentOwnerSignatoryPersonalIdentityNumber
    && validatedInputData.newOwnerSignatoryPersonalIdentityNumber
  )

  const completeCase = (documentsHaveBeenSent = false) => {
    const status = documentsHaveBeenSent ? 'awaiting_sign' : 'ongoing'
    props.updateStatus(status)
  }

  useEffect(() => {
    if (actions && actions.length > 0) {
      console.log('actions', actions)
      const result = doFetchCaseAPI("on-off-case", "save-actions", {caseID:props.caseID, actions:actions})
    }
  }, [actions])

  /*useEffect(() => {
    if (actions.includes('portation') && props.process.type === 'onboarding' && validatedInputData.numberChoice === 'existing') {
      if (validatedInputData.newOwnerOrgnr && !validatedInputData.currentOwnerOrgnr) {
        props.handleNewInputValue({name:"currentOwnerOrgnr", value:validatedInputData.newOwnerOrgnr})
      }
    }
  }, [props.process.type, validatedInputData, actions])*/

  return (
    <div>
      <Header title="Perfekt," />
      <div className={styles.container}>
        <div className="buddy-content-box sorbet">
          {summary.messages.map((message, index) => (
            <Message text={message} key={index} />
          ))}
        </div>
        <div>
          {documents.length > 0 && (
            <DocumentHandling
              documents={documents}
              inputState={props.inputState}
              onInputChange={props.onInputChange}
              completeCase={completeCase}
              caseID={props.caseID}
              caseNumber={props.caseNumber}
              process={props.process}
            />
          )}
          {documents.length == 0 && (
            <div className={pageStyles.buttonContainer}>
              <Button text='Utför ändring' onClickFunction={completeCase} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
    
export default Summary