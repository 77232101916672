import React, {useState, useEffect} from "react"

import Input from "./Input/Input"

import styles from "../css/Page.module.css"

const CurrentOwnerSignatory = props => {

  useEffect(() => {
    if (props.inputState.caseManagerIsSignatory.value == 'yes') {
      if (props.inputState.caseManagerName.validated) {
        props.handleNewInputValue({name:"currentOwnerSignatoryName", value:props.inputState.caseManagerName.value})
      }
      if (props.inputState.caseManagerEmail.validated) {
        props.handleNewInputValue({name:"currentOwnerSignatoryEmail", value:props.inputState.caseManagerEmail.value})
      }
      if (props.inputState.caseManagerPersonalIdentityNumber.validated) {
        props.handleNewInputValue({name:"currentOwnerSignatoryPersonalIdentityNumber", value:props.inputState.caseManagerPersonalIdentityNumber.value})
      }
    }
    }, [
    props.inputState.caseManagerName.value,
    props.inputState.caseManagerEmail.value,
    props.inputState.caseManagerPersonalIdentityNumber.value,
    props.inputState.caseManagerName.validated,
    props.inputState.caseManagerEmail.validated,
    props.inputState.caseManagerPersonalIdentityNumber.validated,
    props.inputState.caseManagerIsSignatory.value
  ])

  useEffect(() => {
    if (props.inputState.currentOwnerSignatoryNameList.value) {
      props.handleNewInputValue({name:"currentOwnerSignatoryName", value:props.inputState.currentOwnerSignatoryNameList.value})
    }
  }, [props.inputState.currentOwnerSignatoryNameList.value])

  let classNames = [styles.inputContainer]

  if (props.hide) {
    classNames.push(styles.hidden)
  }

  return (
	<div className={classNames.join(" ")}>
	  <Input
		name="currentOwnerSignatoryName"
		inputState={props.inputState}
		onInputChange={props.onInputChange}
		hide={props.signatoryNameListOptions.length > 0}
	  />
      <Input
        name="currentOwnerSignatoryNameList"
        inputState={props.inputState}
        onInputChange={props.onInputChange}
        options={props.signatoryNameListOptions}
        hide={props.signatoryNameListOptions.length == 0}
      />
      <Input
        name="currentOwnerSignatoryEmail"
        inputState={props.inputState}
        onInputChange={props.onInputChange}
      />
      <Input
        name="currentOwnerSignatoryPersonalIdentityNumber"
        inputState={props.inputState}
        onInputChange={props.onInputChange}
      />
	</div>
  )
}

export default CurrentOwnerSignatory