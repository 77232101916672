import React, {useState, useEffect} from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"

import styles from "../../css/Page.module.css"

const SimCard = props => {

  return (
    <div>
      <Header title="Behöver ni simkort?" />
      <div className={styles.inputContainer}>
        <Input 
          name="simCardChoice"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input 
          name="simCardSendChoice"
          title="Vart ska simkortet skickas till?"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(props.inputState.simCardChoice.value == 'send')}
        />
        <Input 
          name="simCardIcc"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(props.inputState.simCardChoice.value == 'owned')}
        />
        <Input
          name="newUserAddressStreet"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(props.inputState.simCardSendChoice.value == 'user')}
        />
        <div className={styles.textInputRow}>
          <Input
            name="newUserAddressPostalCode"
            inputState={props.inputState}
            onInputChange={props.onInputChange}
            hide={!(props.inputState.simCardSendChoice.value == 'user')}
          />
          <Input
            name="newUserAddressCity"
            inputState={props.inputState}
            onInputChange={props.onInputChange}
            hide={!(props.inputState.simCardSendChoice.value == 'user')}
          />
        </div>
      </div>
      {/*<NavigationButtons
        inputState={props.inputState}
        process={props.process}
        condition={props.condition}
  />*/}
    </div>
  )
}

export default SimCard