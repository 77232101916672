import React, { useState, useEffect } from "react"

import Button from "../../Button"
import Message from "../../Message"
import PlainTextInput from "../../TextInput"

import styles from "../../../css/Page.module.css"

import useManagerAPI from "../../../hooks/useManagerAPI"

import validateValue from "../../../functions/validateValue"

const Address = props => {

  const onInputChange = e => {
    setData(previousData  => ({...previousData, [e.target.name]: e.target.value}))
  }

  const successfulResponse = (response) => {
    if (!response) return false
    if (response.error) {
      setResponseMessage({text:response.error, error:true})
      return false
    }
    return true
  }

  const validateData = () => {
    if (!validateValue(data.city, 'city')) {
      setResponseMessage({text:'Postort är ej korrekt ifylld', error:true})
      return false
    } 
    if (!validateValue(data.street, 'street')) {
      setResponseMessage({text:'Gatuadress är ej korrekt ifylld', error:true})
      return false
    }
    if (!validateValue(data.postalCode, 'postalCode')) {
      setResponseMessage({text:'Postnummer är ej korrekt ifylld', error:true})
      return false
    }
    return true
  }

  const saveAddress = () => {
    if (!validateData()) return
    setResponseMessage({text:'Uppdaterar adress', error:false})
    setDoFetchSaveAddress(true)
  }

  const onFetchSaveAddressSuccess = () => {
    setDoFetchSaveAddress(false)
    setResponseMessage({text:'Adress uppdaterad', error:false})
    props.updateCompanyAddress(data, props.dataIndex)
  }

  const [data, setData] = useState(props.data)
  const [doFetchSaveAddress, setDoFetchSaveAddress] = useState(false)
  const [responseMessage, setResponseMessage] = useState({text:'', error:false})

  const fetchSaveAddress = useManagerAPI('company', 'save-address', doFetchSaveAddress, {id:props.companyID, address:data}, props.accessToken)

  let classNames = []
  if (props.hide) {
    classNames.push(styles.hidden)
  }
 
  useEffect(() => {
    if (!successfulResponse(fetchSaveAddress.response)) return
    onFetchSaveAddressSuccess()
  }, [fetchSaveAddress.loading])
  
  return(
    <div className={classNames.join(" ")}>
      <div> {/* className={styles.itemContainer} */}
        <div className={styles.inputContainer}>
          <PlainTextInput
            name='name'
            value={data.name || ''}
            onInputChange={onInputChange}
            placeholder='Kostnadsställe'
          />
          <PlainTextInput
            name='street'
            value={data.street || ''}
            onInputChange={onInputChange}
            placeholder='Gatuadress'
          />
          <div className={styles.textInputRow}>
            <PlainTextInput
              name='postalCode'
              value={data.postalCode || ''}
              onInputChange={onInputChange}
              placeholder='Postnummer'
            />
            <PlainTextInput
              name='city'
              value={data.city || ''}
              onInputChange={onInputChange}
              placeholder='Postort'
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            text='Uppdatera adress'
            onClickFunction={saveAddress}
            disabled={fetchSaveAddress.loading}
          />
        </div>
        <Message
          text={responseMessage.text}
          error={responseMessage.error}
          loading={fetchSaveAddress.loading}
        />
      </div>
    </div>
  )
}
    
export default Address