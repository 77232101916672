import React, { useState, useEffect } from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"

import styles from "../../css/Page.module.css"

const NewUser = props => {
  
  return (
    <div>
      <Header title="Vem är ny användare?" />
      <div className={styles.inputContainer}>
        <Input
          name="newUserName"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="newUserEmail"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="startDate"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
      </div>
      {/*<NavigationButtons
        inputState={props.inputState}
        process={props.process}
        condition={props.condition}
  />*/}
    </div>
  )
}
  
export default NewUser