import React, { useState, useEffect } from "react"

import Button from "../../Button"
import Address from "./Address"
import NewAddress from "./NewAddress"

import styles from "../../../css/Page.module.css"

const AddressTab = props => {

  const updateCompanyAddress = (updatedAddress, updatedAddressIndex) => {
    const newAddressList = props.addressList.map((address, index) => {
      if (index === updatedAddressIndex) {
        return updatedAddress
      }
      else {
        return address
      }
    })
    props.setCompany(previousData  => ({...previousData, addressList: newAddressList}))
  }

  const insertCompanyAddress = (newAddress) => {
    props.setCompany(previousData  => ({...previousData, addressList: [
      ...props.addressList,
      newAddress
    ]}))
  }

  const showNewAddress = () => {
    setViewMode('new')
  }

  const [viewMode, setViewMode] = useState('list')

  let classNames = []
  if (props.hide) {
    classNames.push(styles.hidden)
  }

  return(
    <div className={classNames.join(" ")}>
      <div className={styles.separatedItemContainer}>
        {viewMode === 'list' && props.addressList.map((address, index) => (
          <Address
            companyID={props.companyID}
            data={address} 
            dataIndex={index}
            updateCompanyAddress={updateCompanyAddress}
            //successfulResponse={props.successfulResponse}
            //updateResponseMessage={props.updateResponseMessage}
            accessToken={props.accessToken}
            key={index}
          />
        ))}
        {viewMode === 'new' && (
          <NewAddress
            companyID={props.companyID}
            insertCompanyAddress={insertCompanyAddress}
            //successfulResponse={props.successfulResponse}
            //updateResponseMessage={props.updateResponseMessage}
            setViewMode={setViewMode}
            accessToken={props.accessToken}
          />
        )}
        {viewMode !== 'new' && (
          <div className={styles.buttonContainer}>
            <Button
              text='Lägg till ny adress'
              onClickFunction={showNewAddress}
            />
          </div>
        )}
      </div>
    </div>
  )
}
  
export default AddressTab