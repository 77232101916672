import React, { useState, useEffect } from "react"

import { listFunctions as inputListFunctions } from "../../../lists/inputs";

import Button from "../../Button"
import Message from "../../Message"
import TextInput from "../../TextInput"
import RadioInputGroup from "../../RadioInputGroup"
import Select from "../../Select"

import styles from "../../../css/Page.module.css"

import useManagerAPI from "../../../hooks/useManagerAPI"

import validateValue from "../../../functions/validateValue"

const Policy = props => {

  const onInputChange = e => {
    setData(previousData  => ({...previousData, [e.target.name]: e.target.value}))
  }

  const successfulResponse = (response) => {
    if (!response) return false
    if (response.error) {
      setResponseMessage({text:response.error, error:true})
      return false
    }
    return true
  }

  const savePolicy = () => {
    setResponseMessage({text:'Sparar policy', error:false})
    setDoFetchSavePolicy(true)
  }

  const onFetchSavePolicySuccess = () => {
    setDoFetchSavePolicy(false)
    setResponseMessage({text:'Policy sparad', error:false})
    setData(previousData  => ({...previousData, id:fetchSavePolicy.response.policyID}))
    props.updateCompanyPolicy({id:fetchSavePolicy.response.policyID, ...data})
  }

  const [data, setData] = useState(props.data)
  const [doFetchSavePolicy, setDoFetchSavePolicy] = useState(false)
  const [responseMessage, setResponseMessage] = useState({text:'', error:false})

  const fetchSavePolicy = useManagerAPI('company', 'save-policy', doFetchSavePolicy, {id:props.companyID, policy:data}, props.accessToken)

  useEffect(() => {
    if (!successfulResponse(fetchSavePolicy.response)) return
    onFetchSavePolicySuccess()
  }, [fetchSavePolicy.loading])

  return(
    <div>
      <div className={styles.inputContainer}>
        <Select
          title='Primär operatör'
          name='newOperator'
          value={data.newOperator || ''}
          onInputChange={onInputChange}
          options={inputListFunctions.getInput('newOperator').options}
        />
        <RadioInputGroup
          title='Primär surfmängd'
          name='dataPlanChoice'
          value={data.dataPlanChoice || ''}
          onInputChange={onInputChange}
          options={inputListFunctions.getInput('dataPlanChoice').options}
        />
        <TextInput
          name='desiredDataPlan'
          value={data.desiredDataPlan || ''}
          onInputChange={onInputChange}
          placeholder='Primär önskad GB förbrukning'
          hide={data.dataPlanChoice !== 'other'}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          text='Uppdatera policy'
          onClickFunction={savePolicy}
          disabled={fetchSavePolicy.loading}
        />
      </div>
      <Message
        text={responseMessage.text}
        error={responseMessage.error}
        loading={fetchSavePolicy.loading}
      />
    </div>
  )
}

export default Policy