import React from "react"

import Message from "./Message"

import styles from "../css/ErrorMessages.module.css"

const ErrorMessages = props => {
  
  return (
    <div className={styles.container}>
      {props.messages.map((message, index) => (
        <Message text={message} key={index} error={true} />
      ))}
    </div>
  )
}

export default ErrorMessages