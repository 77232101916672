import React, { useState } from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"
import Message from "../../components/Message"

import styles from "../../css/Page.module.css"

const StartOffboarding = props => {
  
  return (
    <div>
      <Header title="Vem avser ärendet?" />
      <div className={styles.inputContainer}>
        <Input
          name="orgnr"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          loading={props.orgnrLoading}
        />
        <Input
          name="currentUserName"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!props.orgnrVerified}
        />
        <Input
          name="currentUserEmail"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!props.orgnrVerified}
        />
        <Input
          name="endDate"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!props.orgnrVerified}
        />
        <Input
          name="number"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!props.orgnrVerified}
        />
      </div>
      <Message text={props.orgnrVerifiedError} error={true} hide={props.orgnrVerified} />
      {/*<NavigationButtons
        inputState={props.inputState}
        process={props.process}
  />*/}
    </div>
  )
}
  
export default StartOffboarding