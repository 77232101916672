import React, { useState, useEffect } from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"

import styles from "../../css/Page.module.css"

const Number = props => {
  const getInputVisibility = () => {
    const inputVisibility = {
      number: 'hidden'
    }
    if (['other', 'existing'].includes(props.inputState.numberChoice.value)) {
      inputVisibility.number = 'visible'
    }
    return inputVisibility
  }
  
  useEffect(() => {
    setInputVisibility(getInputVisibility())
  }, [props.inputState.numberChoice.value])
  
  const [inputVisibility, setInputVisibility] = useState(getInputVisibility())

  return (
    <div>
      <Header title="Vad för mobilnummer avser det?" />
      <div className={styles.inputContainer}>
        <Input
          name="numberChoice"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input
          name="number"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          visibility={inputVisibility.number}
        />
      </div>
      {/*<NavigationButtons
        inputState={props.inputState}
  />*/}
    </div>
  )
}
  
export default Number