import getInputDataForDocuments from "./getInputDataForDocuments"
import getDocumentOptions from "./getDocumentOptions"

const getScriveDocuments = (documents, inputData, process, caseNumber) => {
  let scriveDocuments = []
  const documentInputData = getInputDataForDocuments(inputData, process)
  
  documents.map((document) => {
    scriveDocuments.push({
      name: document.name,
      title: document.title,
      inputData: document.inputs.reduce((dataObject, input) => {
        dataObject[input.name] = documentInputData[input.name] || ''
        return dataObject
      }, {}),
      options: getDocumentOptions(inputData, document, caseNumber)
    })
  })
  return scriveDocuments
}

export default getScriveDocuments