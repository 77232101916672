const inputDiff = (inputState, inputNames) => {
  const allEqual = inputNames.every((name, index, names) => 
    inputState[name].value === inputState[names[0]].value
  )
  const allValidated = inputNames.every((name, index, names) => 
    inputState[name].validated
  )
  return (!allEqual && allValidated) ? true : false
}

export default inputDiff