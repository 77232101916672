const config = {
    api: {
        manager: {
            url: "https://fixanummer.se/api/manager/index.php"
        },
        case: {
            url: "https://fixanummer.se/api/on-off-case/index.php",
            key: "D45gh7gfJ[2f-G19wTYz6<5x"
        }
    },
    pko: {
        fetchURL: {
            db: {
                newCase: "https://pko.nu/on-off-boarding/db/new-case.php",
                newInputValue: "https://pko.nu/on-off-boarding/db/new-input-value.php",
                newDocumentIDs: "https://pko.nu/on-off-boarding/db/new-document-ids.php",
                newActions: "https://pko.nu/on-off-boarding/db/new-actions.php",
                getCaseInfo: "https://pko.nu/on-off-boarding/db/get-case-info.php",
                completeCase: "https://pko.nu/on-off-boarding/db/complete-case.php"
            },
            snpac: {
                getOperator: "https://pko.nu/on-off-boarding/externals/snpac/get-operator.php"
            },
            merinfo: {
                getCompany: "https://pko.nu/on-off-boarding/externals/merinfo/get-company.php"
            },
            api: "https://pko.nu/api/index.php",
            userAPI: "https://pko.nu/on-off-boarding/api/index.php",
            reCaptcha: "https://pko.nu/reCAPTCHA/verify.php"
        },
        fetchKey: "D45gh7gfJ[2f-G19wTYz6<5x"
    },
    company: {
        name: "Buddy",
        seller: {
            name: "Hannah Pontén",
            email: "info@buddy.se"
        }
    },
    document: {
        viewer: "info@buddy.se"
    },
    reCaptcha: {
        url: "https://fixanummer.se/reCAPTCHA/verify.php",
        key: "6Lc0qqUoAAAAAKdGUCmiF8vUw3tUhRGVTdjIOROh"
    },
    zendesk: {
        email: "support@buddy.se" 
    },
    message: {
        closeNumberCost: "Om abonnemanget avslutas kan kostnader faktureras från aktuell operatör. Kostnader kan exempelvis vara kvarvarande bindningstid, förhöjda avgifter eller uppsägningstid."
    },
    documentURL: {
        termsAndConditions: "https://pko.nu/"
    },
    storageKey: {
        accessToken: 'access_token',
        navigatedTo: 'navigated_to'
    }
}

export default config