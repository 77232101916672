const getCaseSummary  = (actions, validatedInputData) => {
  let messages = []
  
  actions.map((action) => {
    switch(action) {
      case 'portation':
        messages.push(
          'Du har valt att göra en portering av nummer '+validatedInputData.number
          + ' från '+validatedInputData.currentOperator
          + ' till '+validatedInputData.newOperator
          + '.'
        )
        break
      case 'transfer':
        messages.push(
          'Du har valt att göra en överlåtelse av nummer '+validatedInputData.number
          + ' från '+validatedInputData.currentOwnerName
          + ' till '+validatedInputData.newOwnerName
          + '.'
        )
        break
      case 'replace':
        messages.push(
          'Du har valt att göra en nummerflytt av nummer '+validatedInputData.number
          + ' som ska ersätta nummer '+validatedInputData.vacantNumber
          + ' från '+validatedInputData.currentOwnerName
          + ' till '+validatedInputData.newOwnerName
          + '.'
        )
        break
    }
  })

  return {
    messages: messages
  }
}

export default getCaseSummary