import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import MenuItem from "./MenuItem";

import styles from "../../../css/Menu.module.css"

const Menu = props => {

  const navigate = useNavigate()

  const goToOpenCompany = () => {
    navigate('/open-company')
    props.setShowMenu(false)
  }
  const goToStart = () => {
    navigate('/')
    props.setShowMenu(false)
  }
  const goToCompanyProfile = () => {
    navigate('/company-profile')
    props.setShowMenu(false)
  }
  const goToLogout = () => {
    navigate('/logout')
    props.setShowMenu(false)
  }

  return (
    <div className={styles.wrapper}>
      <MenuItem text="Byt företag" onClickFunction={() => goToOpenCompany()} />
      {props.isManagerLoggedInWithCompany && (
        <MenuItem text="Startsida" onClickFunction={() => goToStart()} />
      )}
      {props.isManagerLoggedInWithCompany && (
        <MenuItem text="Företagsprofil" onClickFunction={() => goToCompanyProfile()} />
      )}
      <MenuItem text="Logga ut" onClickFunction={() => goToLogout()} />
    </div>
  )
}

export default Menu