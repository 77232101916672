import React, {useState, useEffect} from "react"

import Header from "../../components/Header"
import NavigationButtons from "../../components/NavigationButtons"
import Input from "../../components/Input/Input"

import styles from "../../css/Page.module.css"

const DataPlan = props => {

  const [errorMessages, setErrorMessages] = useState([])

  useEffect(() => {
    if (props.inputState.numberChoice.value == 'existing' && !props.inputState.dataPlanChoice.value) {
      props.handleNewInputValue({name:"dataPlanChoice", value:'unchanged'})
    }
  }, [props.inputState.numberChoice.value])

  return (
    <div>
      <Header title="Vilka abonnemangsval skall det vara?" />
      <div className={styles.inputContainer}>
        <Input
          name="currentOperator"
          title="Nuvarande operatör"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          loading={props.fetchOperatorLoading}
          //setErrorMessages={setErrorMessages}
        />
        <Input 
          name="newOperator"
          title="Byt operatör till (valfritt)"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
        />
        <Input 
          name="dataPlanChoice"
          title="Surfmängd"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          optionsToHide={props.inputState.numberChoice.value != 'existing' ? ['unchanged'] : []}
          //setErrorMessages={setErrorMessages}
        />
        <Input
          name="desiredDataPlan"
          inputState={props.inputState}
          onInputChange={props.onInputChange}
          hide={!(props.inputState.dataPlanChoice.value == 'other')}
          optional={!(props.inputState.dataPlanChoice.value == 'other')}
          //setErrorMessages={setErrorMessages}
        />
      </div>
      {/*<NavigationButtons
        inputState={props.inputState}
        errorMessages={errorMessages}
  />*/}
    </div>
  )
}
  
export default DataPlan