import React, { useState, useEffect } from "react"

import Message from "./Message"
import Title from "./Title"

const ScriveDocuments = props => {
    console.log(props.documents)
  return (
    <div>
      <Title text="Dokumentstatus" />
        {props.documents.map((document, index) => (
          <Message
            text={document.title+": "+document.status}
            key={index}
          />
        ))}
    </div>
  )
}

export default ScriveDocuments