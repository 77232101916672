import React, { useState, useEffect } from "react"

import Button from "../../Button"
import Message from "../../Message"

import styles from "../../../css/Page.module.css"

import useManagerAPI from "../../../hooks/useManagerAPI"

const Manager = props => {

  const onInputChange = e => {
    setData(previousData  => ({...previousData, [e.target.name]: e.target.value}))
  }
    
  const successfulResponse = (response) => {
    if (!response) return false
    if (response.error) {
      setResponseMessage({text:response.error, error:true})
      return false
    }
    return true
  }

  const removeManager = () => {
    setResponseMessage({text:'Tar bort administratör', error:false})
    setDoFetchRemoveManager(true)
  }

  const onFetchRemoveManagerSuccess = () => {
    setDoFetchRemoveManager(false)
    setResponseMessage({text:'Administratör borttagen', error:false})
    props.removeCompanyManager(data.id)
  }

  const [data, setData] = useState(props.data)
  const [doFetchRemoveManager, setDoFetchRemoveManager] = useState(false)
  const [responseMessage, setResponseMessage] = useState({text:'', error:false})

  const fetchRemoveManager = useManagerAPI('company', 'remove-manager', doFetchRemoveManager, {id:props.companyID, managerID:data.id}, props.accessToken)

  useEffect(() => {
    if (!successfulResponse(fetchRemoveManager.response)) return
    onFetchRemoveManagerSuccess()
  }, [fetchRemoveManager.loading])

  return (
    <div>
      <div className={styles.itemContainer}>
        <Message text={data.name} />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          text='Ta bort administratör'
          onClickFunction={removeManager}
          disabled={fetchRemoveManager.loading}
        />
      </div>
      <Message
        text={responseMessage.text}
        error={responseMessage.error}
        loading={fetchRemoveManager.loading}
      />
    </div>
  )
}

export default Manager