import inputs from "../lists/inputs";

const getValidatedInputData = (inputState) => {
    
  let inputData = {}
    
  inputs.map((input) => {
    if (inputState[input.name].validated) {
      inputData[input.name] = inputState[input.name].value
    }
  })

  return inputData
}

export default getValidatedInputData