import React, { useState, useEffect } from "react"

import Button from "../Button"
import Message from "../Message"
import TableList from "../TableList"

import useMerinfo from "../../hooks/useMerinfo"

import validateValue from "../../functions/validateValue"

import styles from "../../css/Page.module.css"

const MerinfoCompanyInfo = props => {

  const successfulResponse = (response) => {
    if (!response) return false
    setDoFetch(false)
    if (response.error) {
      setResponseMessage({text:response.error, error:true})
      return false
    }
    return true
  }

  const onFetchCompanyInfoSuccess = () => {
    setResponseMessage({text:'', error:false})
    props.setCompanyInfo({
      orgnr: props.orgnr,
      name: fetchCompanyInfo.response.company.basic.name,
      address: {
        name: '',
        city: fetchCompanyInfo.response.company.basic.city,
        street: fetchCompanyInfo.response.company.basic.address,
        postalCode: fetchCompanyInfo.response.company.basic.zipcode
      }
    })
  }

  const [doFetch, setDoFetch] = useState(false)
  const [responseMessage, setResponseMessage] = useState({})

  const fetchCompanyInfo = useMerinfo('getCompany', doFetch, {orgnr:props.orgnr})

  useEffect(() => {
    if (validateValue(props.orgnr, 'orgnr')) {
      setResponseMessage({text:'Hämtar företagsinfo', error:false})
      setDoFetch(true)
    }
  }, [props.orgnr])

  useEffect(() => {
    if (!successfulResponse(fetchCompanyInfo.response)) return
    onFetchCompanyInfoSuccess()
  }, [fetchCompanyInfo.loading])

  return (
    <div>
      {fetchCompanyInfo.response && fetchCompanyInfo.response.company && (
        <TableList
          items={[
            {
              title: 'Företagsnamn',
              text: fetchCompanyInfo.response.company.basic.name
            },
            {
              title: 'Adress',
              text: fetchCompanyInfo.response.company.basic.address+', '
                +fetchCompanyInfo.response.company.basic.zipcode+' '
                +fetchCompanyInfo.response.company.basic.city
            }
          ]}
        />
      )}
      <Message
        text={responseMessage.text}
        error={responseMessage.error}
        loading={fetchCompanyInfo.loading}
      />
    </div>
  )
}

export default MerinfoCompanyInfo