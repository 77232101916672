import { useState, useEffect } from "react";

import config from "../config"

const useAPI = (controller, method, doFetch, data) => {
  const [response, setResponse] = useState(null)
  const [loading, setLoading] = useState(false)

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+config.pko.fetchKey,  // "Authorization": "Bearer "+config.pko.fetchKey
      "Cache-Control": "no-cache"
    },
    body: JSON.stringify(data)
  };

  const url = config.pko.fetchURL.api+"/"+controller+"/"+method
  //const url = config.pko.fetchURL.merinfo[action]

  useEffect(() => {
    setLoading(doFetch)
    if (doFetch) {
      fetch(url, options)
        .then((response) => response.json())
        .then((data) => setResponse(data))
        .catch((error) => console.error("Error:", error))
        .finally(() => setLoading(false))
    }
  }, [doFetch]);

  return {response:response, loading:loading}
}

export default useAPI