import { listFunctions as documentListFunctions } from "../lists/documents";
import getCaseSpecificDocumentAction from "./getCaseSpecificDocumentAction"

const getCaseDocuments = (process, actions, validatedInputData) => {
  let documents = []
  const documentActions = getDocumentActions(actions)
  documentActions.map((action) => {
    const documentAction = getCaseSpecificDocumentAction(process, action, validatedInputData) || action
    const documentOperator = getDocumentOperator(validatedInputData, documentAction)
    const document = documentListFunctions.getDocument(documentAction, documentOperator)
    if (document) {
      documents.push(document)
    }
  })
  return documents
}

const getDocumentActions = (actions) => {
  if (actions.includes('transfer') && actions.includes('portation')) {
    return actions.filter(action => { return !['transfer', 'portation'].includes(action) }).concat(['portationTransfer'])
  }
  return actions
}

const getDocumentOperator = (validatedInputData, documentAction) => {
  if (['portation', 'portationTransfer'].includes(documentAction)) {
    return validatedInputData.newOperator
  }
  return validatedInputData.currentOperator
}

/*const getActionsToIgnore = (actions, validatedInputData) => {
  let actionsToIgnore = []
  const ignoreTransferIfPortationOperators = ['Telia', 'Telenor', 'Tele2']
  if (
    ignoreTransferIfPortationOperators.includes(validatedInputData.newOperator) &&
    actions.includes('transfer') &&
    actions.includes('portation')
  ) {
    actionsToIgnore.push('transfer')
  }
  return actionsToIgnore
}

const getDocumentActions = (actions, actionsToIgnore) => {
  const documentActions = actions.filter(action => { return !actionsToIgnore.includes(action) })
  return documentActions
}

const getDocumentAction = (caseSpecificDocumentAction, actionsToIgnore) => {
  if (
    ['portation', 'inportation'].includes(caseSpecificDocumentAction) &&
    actionsToIgnore.includes('transfer')
  ) {
    return caseSpecificDocumentAction+'-transfer'
  }
  return caseSpecificDocumentAction
}*/

export default getCaseDocuments