import React from "react"
import { useNavigate } from "react-router-dom"

import changePage from "../functions/changePage"

import styles from "../css/Button.module.css"

const Button = props => {
  const navigate = useNavigate();

  let classNames = [styles.button]
  classNames.push(props.disabled ? styles.disabled : styles.enabled)
  if (props.loading) {
    classNames.push(styles.loading)
  }

  const onClick = () => {
    if (props.disabled) {
      return false
    }
    if (typeof(props.onClickFunction) === 'function') {
      props.onClickFunction()
    }
    if (props.navigateTo) {
      changePage(props.navigateTo, navigate)
    }
    return true
  }

  return (
    <div onClick={() => onClick()}>
      <div className={classNames.join(" ")}>
        <div className={styles.text}>
          {props.text}
        </div>
      </div>
    </div>
  )
}

export default Button