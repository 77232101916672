import config from "../config"

import getCompanyType from "./getCompanyType"
import getUserEmailInputName from "./getUserEmailInputName"

const getInputDataForDocuments = (inputData, process) => {
  
  const getAddressComplete = (street, postalCode, city) => {
    let addressComplete = ''
    if (street && postalCode && city) {
      addressComplete = street+', '+postalCode+' '+city
    }
    return addressComplete
  }
  const getAddressPostalCodeCity = (postalCode, city) => {
    let addressPostalCodeCity = ''
    if (postalCode && city) {
      addressPostalCodeCity = postalCode+' '+city
    }
    return addressPostalCodeCity
  }
  const getStartDate = (date) => {
    return date || getCurrentDate()
  }
  const getCurrentDate = (modifyMonth = 0) => {
    const date = new Date()
    if (modifyMonth) {
      date.setMonth(date.getMonth()+parseInt(modifyMonth))
    }
    return date.toLocaleDateString()
  }
  const getDocumentViewers = (inputData) => {
    let viewers = [config.zendesk.email]
    const userEmailInputName = getUserEmailInputName(process)
    if (inputData.caseManagerIsSignatory == 'no' && inputData.caseManagerEmail) {
      viewers.push(inputData.caseManagerEmail)
    }
    if (inputData.isUserDocumentViewer && inputData[userEmailInputName]) {
      viewers.push(inputData[userEmailInputName])
    }
    return viewers
  }
  const formatOrgnr = (orgnr) => {
    return orgnr ? orgnr.replace(/[^0-9]/g, '') : ''
  }

  const documentInputData = {
    number: inputData.number || '',
    numberCount: '1',
    vacantNumber: inputData.vacantNumber || '',
    vacantNumberText: inputData.vacantNumber ? 'Tillfälligt nummer: '+inputData.vacantNumber : '',
    portationDate: getStartDate(inputData.startDate),
    transferDate: getStartDate(inputData.startDate),
    mandateExpirationDate: getCurrentDate(3),
    currentOperator: inputData.currentOperator || '',
    currentOwnerName: inputData.currentOwnerName || '',
    currentOwnerOrgnr: formatOrgnr(inputData.currentOwnerOrgnr),
    currentOwnerSignatoryName: inputData.currentOwnerSignatoryName || '',
    currentOwnerSignatoryEmail: inputData.currentOwnerSignatoryEmail || '',
    currentOwnerSignatoryPersonalIdentityNumber: inputData.currentOwnerSignatoryPersonalIdentityNumber || '',
    currentOwnerAddressStreet: inputData.currentOwnerAddressStreet || '',
    currentOwnerAddressPostalCode: inputData.currentOwnerAddressPostalCode || '',
    currentOwnerAddressCity: inputData.currentOwnerAddressCity || '',
    currentOwnerAddressComplete: getAddressComplete(
      inputData.currentOwnerAddressStreet || '',
      inputData.currentOwnerAddressPostalCode || '',
      inputData.currentOwnerAddressCity || ''
    ),
    currentOwnerAddressPostalCodeCity: getAddressPostalCodeCity(
      inputData.currentOwnerAddressPostalCode || '',
      inputData.currentOwnerAddressCity || ''
    ),
    newOwnerName: inputData.newOwnerName || '',
    newOwnerOrgnr: formatOrgnr(inputData.newOwnerOrgnr),
    newOwnerSignatoryName: inputData.newOwnerSignatoryName || '',
    newOwnerSignatoryEmail: inputData.newOwnerSignatoryEmail || '',
    newOwnerSignatoryPersonalIdentityNumber: inputData.newOwnerSignatoryPersonalIdentityNumber || '',
    newOwnerAddressStreet: inputData.newOwnerAddressStreet || '',
    newOwnerAddressPostalCode: inputData.newOwnerAddressPostalCode || '',
    newOwnerAddressCity: inputData.newOwnerAddressCity || '',
    newOwnerAddressComplete: getAddressComplete(
      inputData.newOwnerAddressStreet || '',
      inputData.newOwnerAddressPostalCode || '',
      inputData.newOwnerAddressCity || ''
    ),
    newOwnerAddressPostalCodeCity: getAddressPostalCodeCity(
      inputData.newOwnerAddressPostalCode || '',
      inputData.newOwnerAddressCity || ''
    ),
    currentOwnerIsPrivate: (getCompanyType(inputData.currentOwnerOrgnr) == 'private'),
    currentOwnerIsCompany: (getCompanyType(inputData.currentOwnerOrgnr) == 'company'),
    newOwnerIsPrivate: (getCompanyType(inputData.newOwnerOrgnr) == 'private'),
    newOwnerIsCompany: (getCompanyType(inputData.newOwnerOrgnr) == 'company'),
    caseSellerCompany: config.company.name,
    caseSellerName: config.company.seller.name,
    caseSellerEmail: config.company.seller.email,
    isMobile: true,
    shallReplaceNumber: (inputData.moveNumberChoice == 'subscription'),
    sendSimCard: (inputData.simCardChoice == 'send'),
    acceptMandate: true,
    documentViewers: getDocumentViewers(inputData)
  }

  return documentInputData
}

export default getInputDataForDocuments