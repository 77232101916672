import React from "react"

import Radio from "./Radio"

const RadioGroup = props => {
  const options = props.options.filter(option => {
    return props.optionsToHide ? !props.optionsToHide.includes(option.value) : true
  })

  return (
    options.map((option, index) => (
      <Radio
        key={index}
        label={option.label}
        name={props.name}
        value={option.value}
        inputState={props.inputState}
        onInputChange={props.onInputChange}
      />
    ))
  )
}

export default RadioGroup