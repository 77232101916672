import React, { useState, useEffect, useReducer } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import Button from "./Button"
import ErrorMessages from "./ErrorMessages"

import changePage from "../functions/changePage"

import { listFunctions as pageListFunctions } from "../lists/pages";

import styles from "../css/NavigationButtons.module.css"

const NavigationButtons = props => {
  const location = useLocation()
  const navigate = useNavigate()

  const [nextPath, setNextPath] = useState('')
  const [previousPath, setPreviousPath] = useState('')
  const [displayErrorMessages, setDisplayErrorMessages] = useState(false)
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0)

  const onButtonNextClick = () => {
    console.log(nextPath)
    if (props.errorMessages && props.errorMessages.length > 0) {
      setDisplayErrorMessages(true)
      forceUpdate()
    }
    else {
      setDisplayErrorMessages(false)
      changePage(nextPath, navigate)
    }
  }

  useEffect(() => {
    setPreviousPath(props.pageTrail.length > 0 ? props.pageTrail[(props.pageTrail.length-1)] : 'previous')
  }, [props.pageTrail])

  useEffect(() => {
    if (props.currentPath) {
      const page = pageListFunctions.getPage(props.currentPath)
      setNextPath(page.getNextPath(props.inputState, props.process, props.condition))
    }
  }, [props.currentPath, props.inputState, props.process, props.condition])

  return (
    <div>
      <div className={styles.buttonContainer}>
        <Button
          text='Bakåt'
          navigateTo='previous'
        />
        <Button
          text='Fortsätt'
          //navigateTo={inputErrorMessages.length == 0 ? nextPath : ''}
          onClickFunction={onButtonNextClick}
          disabled={props.disableForwardNavigation || false}
        />
      </div>
      {displayErrorMessages && (
        <ErrorMessages messages={props.errorMessages || []} />
      )}
    </div>
  )
}
    
export default NavigationButtons